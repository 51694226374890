import React, { memo } from "react"

import { ActionsCell } from "shared/ui/berry-jass"
import { useEditTaskAction } from "features/activity-task-edit"
import { useDeleteTaskAction } from "features/activity-task-delete"

export const ActivityTasksActionsCell = memo(
  /**
   * @param {object} props
   * @param {string} props.id
   * @param {object} props.task
   */
  ({ id, task }) => {
    const editTaskAction = useEditTaskAction()
    const deleteTaskAction = useDeleteTaskAction()
    const actions = [editTaskAction(task), deleteTaskAction(task)]

    return <ActionsCell id={id} actions={actions} />
  }
)

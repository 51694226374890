import React from "react"
import { Translate } from "react-redux-i18n"
import { Stack, Typography } from "@mui/material"

import { WhiteLabelLoginCard } from "shared/ui/berry-jass"

export const ChangePasswordRequestSent = () => {
  return (
    <WhiteLabelLoginCard>
      <Stack gap={1}>
        <Typography variant="h2">
          <Translate value="change_password.check-email" />
        </Typography>
      </Stack>
      <Typography variant="h4" fontWeight="normal">
        <Translate value="change_password.email-sent" />
      </Typography>
    </WhiteLabelLoginCard>
  )
}

import React, { useCallback } from "react"
import { Translate } from "react-redux-i18n"
import { useHandleDeleteTask } from "./handlers"

export const useDeleteTaskAction = () => {
  const deleteTask = useHandleDeleteTask()
  return useCallback(
    (task) => {
      return {
        label: <Translate value="delete.title" />,
        onClick: () => deleteTask(task)
      }
    },
    [deleteTask]
  )
}

import { useInfiniteQuery } from "@tanstack/react-query"
import client from "utils/client"
import { getNextPageParam } from "shared/lib/ransack"

export const useFeedItems = (params, options = {}) => {
  return useInfiniteQuery({
    queryKey: ["v2", "activities", "feed_items", "index", params],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await client.get("/v2/activities/feed_items.json", {
        params: { ...params, page: pageParam }
      })

      return res.data
    },
    getNextPageParam,
    ...options
  })
}

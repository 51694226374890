import React, { memo } from "react"
import { I18n, Translate } from "react-redux-i18n"
import {
  Typography,
  FormControlLabel,
  Switch,
  Divider,
  Stack
} from "@mui/material"

import { HorizontalStack } from "shared/ui/berry-jass"
import { RemoteAutocomplete } from "shared/ui"
import { SearchInput } from "shared/ui/berry-jass-extended"
import { CreateTodoTaskFab } from "features/activity-task-create"
import { getAlternativeContactsOptionLabel } from "entities/contact"
import { contactAdditionalOptions } from "../lib"

export const ActivityTasksFilters = memo(
  ({
    useFilters,
    setTaskContact,
    setSearchTodoTask,
    setShowDoneTodos,
    showCustomer
  }) => {
    const { contact, japCustomer, searchTodoTask, showDoneTodos } = useFilters()

    return (
      <Stack gap={1}>
        <HorizontalStack gap={1}>
          <SearchInput
            fullWidth
            id="search"
            initialValue={searchTodoTask}
            onChange={setSearchTodoTask}
            size="small"
            placeholder={I18n.t("search.title")}
          />
          <CreateTodoTaskFab
            showCustomer={showCustomer}
            customer={japCustomer}
            contact={contact}
            sx={{ p: 2 }}
          />
        </HorizontalStack>

        <RemoteAutocomplete
          paginated
          id="contact_filter"
          size="small"
          disabled={!japCustomer?.id}
          data-testid="contact-select"
          additionalOptions={contactAdditionalOptions}
          label={<Translate value="activities-center.filters.contact" />}
          mapResponseToDataSource={(response) => response?.data ?? []}
          onChange={(_e, value) => setTaskContact(value)}
          url="/autocomplete/jap_customers/contacts"
          value={contact?.id ? contact : null}
          params={{ q: { by_customer_id: japCustomer?.id } }}
          variant="ransack"
          searchProp="q.first_name_or_last_name_or_phone_or_email_or_company_cont"
          getOptionLabel={getAlternativeContactsOptionLabel}
        />

        <FormControlLabel
          sx={{ mr: 0, py: 1 }}
          control={
            <Switch
              name="show_closed_todos"
              checked={showDoneTodos}
              onChange={(e, value) => setShowDoneTodos(value)}
            />
          }
          label={
            <Typography variant="h6">
              <Translate value="activities.show-closed-todos" />
            </Typography>
          }
        />
        <Divider variant="fullWidth" />
      </Stack>
    )
  }
)

import React from "react"
import { Translate } from "react-redux-i18n"
import { Box, Stack, Typography } from "@mui/material"
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined"
import moment from "moment"

import { localizedDateTime, localizedTime } from "shared"
import { CustomerPerformanceLink } from "entities/activity"

export const ActivityEventCell = ({ activity, showCustomer }) => {
  const { title, start_at, finish_at, location } = activity

  return (
    <Stack spacing={1}>
      <Box display="inline-flex" alignItems="center">
        <Box
          sx={{
            display: "inline-flex",
            flexDirection: "row",
            gap: 0,
            alignItems: "center"
          }}
        >
          <Typography
            sx={{ fontSize: 14, fontWeight: 500, maxWidth: "265px" }}
            component="span"
          >
            {title}
          </Typography>
          {moment(start_at).isSame(new Date(), "day") && (
            <Typography
              sx={{
                fontSize: "12px",
                lineHeight: "14px",
                fontWeight: 400,
                position: "absolute",
                right: "12px",
                color: "red"
              }}
              component="span"
            >
              <Translate value="activity-events.today" />:{" "}
              {localizedTime(start_at)}
            </Typography>
          )}
          {moment(start_at).subtract(1, "day").isSame(new Date(), "day") && (
            <Typography
              sx={{
                fontSize: "12px",
                lineHeight: "14px",
                fontWeight: 400,
                position: "absolute",
                right: "12px"
              }}
              component="span"
            >
              <Translate value="activity-events.tomorrow" />:{" "}
              {localizedTime(start_at)}
            </Typography>
          )}
        </Box>
      </Box>
      <Stack spacing={0.5}>
        <Box display="flex" alignItems="center">
          <CalendarTodayIcon
            color="primary"
            sx={{ fontSize: "16px", mr: "8px" }}
          />
          <Typography sx={{ fontSize: 12, fontWeight: 400 }}>
            <Translate
              value="activities.start"
              date={localizedDateTime(start_at, { seconds: false })}
            />
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <CalendarTodayIcon
            color="primary"
            sx={{ fontSize: "16px", mr: "8px" }}
          />
          <Typography sx={{ fontSize: 12, fontWeight: 400 }}>
            <Translate
              value="activities.end"
              date={localizedDateTime(finish_at, { seconds: false })}
            />
          </Typography>
        </Box>
        {location && (
          <Box display="flex" alignItems="center">
            <LocationOnOutlinedIcon
              color="primary"
              sx={{ fontSize: "16px", mr: "8px" }}
            />
            <Typography sx={{ fontSize: 12, fontWeight: 400 }}>
              {location}
            </Typography>
          </Box>
        )}

        {showCustomer && <CustomerPerformanceLink activity={activity} />}
      </Stack>
    </Stack>
  )
}

export const getCityProps = ({ country = "DE", zipCode }) => {
  return {
    freeSolo: true,
    paginated: true,
    leading: false,
    variant: "ransack",
    getOptionKey: (option) => option?.city ?? option,
    mapResponseToDataSource: (response) => response?.data ?? [],
    url: "/v2/postal_codes/locations",
    searchProp: "q.city_start",
    params: {
      q: {
        country_code_i_cont: country,
        zip_code_eq: zipCode,
        uniq_by_city_and_score: zipCode ? false : true,
        sorts: zipCode ? "score desc" : null
      }
    },
    inputValueMapper: (city) => ({ city }),
    getOptionLabel: (option) =>
      typeof option === "string" ? option : option.city,
    isOptionEqualToValue: (option, value) => option.city === value.city
  }
}

export const getZipProps = ({ country = "DE", city }) => {
  return {
    freeSolo: true,
    paginated: true,
    leading: false,
    variant: "ransack",
    getOptionKey: (option) => option.zip_code ?? option,
    mapResponseToDataSource: (response) => response?.data ?? [],
    url: "/v2/postal_codes/locations",
    searchProp: "q.zip_code_start",
    params: {
      q: {
        country_code_i_cont: country,
        city_eq: city,
        uniq_by_zip_code_and_score: city ? false : true,
        sorts: city ? "score desc" : null
      }
    },
    inputValueMapper: (zip_code) => ({ zip_code }),
    getOptionLabel: (option) =>
      typeof option === "string" ? option : option.zip_code,
    isOptionEqualToValue: (option, value) => option.zip_code === value.zip_code
  }
}

export const getZipPropsByIndex = (i, values) => {
  const country = values.country?.id
  const location = values.locations[i]
  const city = location.city?.city ?? location.city

  return getZipProps({
    country,
    city
  })
}

export const getCityPropsByIndex = (i, values) => {
  const country = values.country?.id
  const location = values.locations[i]
  const zipCode = location.zip?.zip_code ?? location.zip

  return getCityProps({
    country,
    zipCode
  })
}

export const getCityPropsLegacy = ({ country = "DE", zipCode }) => {
  const { getOptionLabel, ...rest } = getCityProps({ country, zipCode })

  // https://github.com/JedWatson/react-select/issues/3417
  const getNewOptionData = (inputValue, optionLabel) => {
    return {
      label: optionLabel,
      city: inputValue,
      __isNew__: true
    }
  }

  return {
    ...rest,
    creatable: true,
    dataSourceConfig: { text: "city", value: "city" },
    getNewOptionData,
    getOptionValue: (option) => option.label
  }
}

export const getZipPropsLegacy = ({ country = "DE", city }) => {
  const { getOptionLabel, ...rest } = getZipProps({ country, city })

  // https://github.com/JedWatson/react-select/issues/3417
  const getNewOptionData = (inputValue, optionLabel) => {
    return {
      label: optionLabel,
      zip_code: inputValue,
      __isNew__: true
    }
  }

  return {
    ...rest,
    creatable: true,
    dataSourceConfig: { text: "zip_code", value: "zip_code" },
    getNewOptionData,
    getOptionValue: (option) => option.label
  }
}

export const getZipPropsLegacyByIndex = (i, values, entity) => {
  const country = values[entity][i]?.country?.value
  const location = values[entity][i]
  const city = location?.city?.city ?? location?.city

  return getZipPropsLegacy({
    country,
    city
  })
}

export const getCityPropsLegacyByIndex = (i, values, entity) => {
  const country = values[entity][i]?.country?.value
  const location = values[entity][i]
  const zipCode = location?.zip?.zip_code ?? location?.zip

  return getCityPropsLegacy({
    country,
    zipCode
  })
}

import { useInView } from "react-intersection-observer"

import { useFeedItems } from "../api"

export const useInfiniteActivityFeedItems = ({ customerId, user, search }) => {
  const query = useFeedItems({
    q: {
      by_jap_customer: customerId,
      by_text: search,
      creator_id_eq: user ? user.id : undefined
    }
  })

  const { ref } = useInView({
    onChange: (inView) => {
      if (inView && !query.isFetchingNextPage) {
        query.fetchNextPage()
      }
    }
  })

  return {
    query,
    ref,
    showLoading: query.data && query.hasNextPage
  }
}

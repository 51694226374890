import React from "react"
import { Field, Form } from "formik"
import { Translate } from "react-redux-i18n"
import { Button, Stack, TextField, Typography } from "@mui/material"

import { WhiteLabelLoginCard } from "shared/ui/berry-jass"
import { PasswordTextField } from "shared/ui/berry-jass-formik"

export const ChangePasswordForm = ({ passwordToken, confirmationToken }) => {
  return (
    <WhiteLabelLoginCard component={Form}>
      <Stack gap={1}>
        <Typography variant="h2">
          <Translate value="change_password.title" />
        </Typography>
      </Stack>
      {!passwordToken && !confirmationToken && (
        <Field
          name="current_password"
          type="password"
          component={TextField}
          label={<Translate value="change_password.current_password" />}
          validate={(value) => !value && "required"}
        />
      )}
      <Field
        fullWidth
        name="password"
        component={PasswordTextField}
        label={<Translate value="change_password.password" />}
      />
      <Field
        fullWidth
        name="password_confirmation"
        component={PasswordTextField}
        label={<Translate value="change_password.password_confirmation" />}
      />

      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ textTransform: "unset" }}
      >
        <Translate value="change_password.submit" />
      </Button>
    </WhiteLabelLoginCard>
  )
}

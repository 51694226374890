import React, { memo } from "react"
import { Translate } from "react-redux-i18n"

import { getTableMeta } from "shared/lib/ransack"
import { DataTable } from "shared/ui/berry-jass-extended"
import { borderlessTableWithRelativeTrSx } from "shared/ui/berry-jass"
import { ActivityEventsTableSubComponent } from "entities/activity-event"
import {
  useFilteredActivityEvents,
  useActivityEventsTableProps
} from "../model"

const ActivityEventsTableComponent = ({ showCustomer, useFilters, user }) => {
  const { japCustomer } = useFilters()
  const activityEvents = useFilteredActivityEvents(useFilters, user)
  const meta = getTableMeta(activityEvents)
  const getTableProps = useActivityEventsTableProps(
    japCustomer?.id,
    showCustomer
  )

  return (
    <DataTable
      size="small"
      hideHeader
      meta={meta}
      sx={borderlessTableWithRelativeTrSx}
      emptyStateProps={{
        sx: { mt: 2, pb: 2 },
        text: <Translate value="activities.no-activity-events" />,
        subText: <Translate value="activities.create-activity-events" />
      }}
      renderSubComponent={({ row }) => (
        <ActivityEventsTableSubComponent
          row={row}
          customerId={japCustomer?.id}
        />
      )}
      getRowCanExpand={(row) => {
        const { contact, with_todo_tasks, description } = row.original

        return !!contact || with_todo_tasks || !!description
      }}
      {...getTableProps(activityEvents)}
    />
  )
}

export const ActivityEventsTable = memo(ActivityEventsTableComponent)

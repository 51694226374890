import moment from "moment"
import { I18n } from "react-redux-i18n"
import { array, bool, boolean, date, mixed, number, object, string } from "yup"
import { v4 as uuidv4 } from "uuid"

export const getProductNameByLocale = (product, locale) => {
  return locale === "en" ? product.name_en || product.name : product.name
}

export const formatSalesProductShort = (salesProduct, locale = "de") => {
  const productName = getProductNameByLocale(salesProduct, locale)

  return `${productName} (${salesProduct.runtime_in_days})`
}

export const formatSalesProduct = (salesProduct, locale = "de") => {
  const productName = getProductNameByLocale(salesProduct, locale)

  return `${salesProduct.supplier_name} | ${productName} (${salesProduct.runtime_in_days})`
}

export const getProductWithSupplier = (product, locale = "de") => {
  const productName = getProductNameByLocale(
    { name: product.product_name, name_en: product.product_name_en },
    locale
  )

  return `${product.supplier_name} - ${productName}`
}

export const getProductTypes = () => [
  {
    id: "OnlineJobAd",
    name: I18n.t("product.product-type.online-job-ad")
  },
  {
    id: "ApplicantDatabase",
    name: I18n.t("product.product-type.applicant-database")
  },
  { id: "AddOn", name: I18n.t("product.product-type.add-on") },
  {
    id: "CustomProduct",
    name: I18n.t("product.product-type.custom-product")
  }
]

export const createDefaultLocationCity = () => {
  return {
    id: null,
    name: I18n.t("product.settings.locations.options.locations", { count: 0 })
  }
}

export const getLocationCities = () => {
  const locations = [createDefaultLocationCity()]

  for (let i = 1; i <= 10; i++) {
    locations.push({
      id: i,
      name: I18n.t("product.settings.locations.options.locations", { count: i })
    })
  }

  return locations
}

export const findLocationCity = (locationCityId) => {
  return getLocationCities().find((city) => city.id === locationCityId)
}

export const createBlankProductPrice = () => ({
  virtualId: uuidv4(),
  id: null,
  from: "",
  to: "",
  market_price: "",
  price: "",
  lower_limit: ""
})

export const salesProductPriceSchema = object({
  rrp: string().price("price").required("required"),
  seller_lower_limit: string().price("price"),
  tl_price: string().price("price"),
  cross_selling_ids: mixed().transform((value) => value?.id),
  add_on_ids: array().transform((value) => value.map((addon) => addon.id)),
  online_job_ad_ids: array()
    .transform((value) => value.map((product) => product.id))
    .when("type", {
      is: (type) => type === "AddOn",
      then: (schema) => schema.min(1, "at-least-one-must-be-selected")
    }),
  min_price_in_package: string().price("price"),
  promotion_enabled: bool(),
  promotion_price: number().when("promotion_enabled", {
    is: Boolean,
    then: number().nullable().required("required"),
    otherwise: number().nullable()
  }),
  default_agency_price: string().when("default_agency_price_enabled", {
    is: Boolean,
    then: string().price("price").required("required"),
    otherwise: string().price("price")
  }),
  default_partner_price: string().when("default_partner_price_enabled", {
    is: Boolean,
    then: string().price("price").required("required"),
    otherwise: string().price("price")
  }),
  promotion_valid_until: date().when("promotion_enabled", {
    is: Boolean,
    then: date()
      .nullable()
      .required("required")
      .min(moment().startOf("day").add(1, "days").toDate(), "date-in-past"),
    otherwise: date().nullable()
  })
}).shape(
  {
    points: string().when("price_in_package", {
      is: (price_in_package) => !price_in_package,
      then: string().price("price").required("required"),
      otherwise: string().oneOf([""], I18n.t("products.points.mandatory"))
    }),
    price_in_package: string().when("points", {
      is: (points) => !points,
      then: string().price("price").required("required"),
      otherwise: string().oneOf([""], I18n.t("products.points.mandatory"))
    })
  },
  [["price_in_package", "points"]]
)

const priceSchema = object({
  from: number().required("required").min(1).typeError("number"),
  to: number()
    .required("required")
    .typeError("number")
    .when("from", (from, schema) => {
      return from ? schema.min(from) : undefined
    }),
  market_price: string().price("price"),
  price: string().price("price").required("required"),
  rrp: string().price("price"),
  lower_limit: string().price("price"),
  _destroy: boolean()
})

export const gradedPricesSchema = object({
  product_prices_attributes: array()
    .of(priceSchema)
    .test(
      "range",
      I18n.t("errors.ranges-must-not-overlap"),
      (productPrices) => {
        let valid = true
        productPrices
          .filter((price) => !price._destroy)
          .forEach((productPrice, index) => {
            if (
              !productPrice.from ||
              !productPrice.to ||
              !productPrices[index - 1]?.to
            ) {
              return
            }
            if (
              (index === 0 &&
                productPrice.from === 1 &&
                productPrice.to >= productPrice.from) ||
              (productPrice.from > productPrices[index - 1].to &&
                productPrice.to >= productPrice.from)
            ) {
              return
            }
            valid = false
          })
        return valid
      }
    )
    .min(1, I18n.t("errors.at-least-one-must-be-filled"))
})

export const metadataSchema = object({
  product_setting_attributes: object({
    design_html: bool(),
    design_text: bool(),
    design_structured: bool(),
    trackgin_views: bool(),
    tracking_clicks: bool(),
    cities: mixed()
      .transform((value) => value?.id)
      .nullable()
  })
})

export const salesProductSchema = object({
  id: number().nullable(),
  name: string().required("required"),
  name_en: string(),
  supplier_group_ids: mixed().transform((groups) =>
    groups.map((group) => group?.id)
  ),
  generic: bool().nullable(),
  type: mixed()
    .transform((value) => value?.id)
    .oneOf(getProductTypes().map((type) => type.id))
    .required("required"),
  supplier_id: mixed()
    .transform((value) => value?.id)
    .required("required"),
  ek_supplier_id: mixed()
    .transform((value) => value?.id)
    .required("required"),
  runtime_in_days: number().min(0).required("required"),
  purchase_product_id: mixed()
    .transform((value) => value?.id)
    .required("required"),
  job_board: string().nullable(),
  country: mixed()
    .transform((values) =>
      values
        .map((value) => value?.id)
        .filter(Boolean)
        .join(",")
    )
    .required("required"),
  is_show: bool(),
  top: bool(),
  excluded_from_prediction: bool(),
  extending: bool(),
  expiring_in_days: number()
    .emptyToNull()
    .when("extending", {
      is: Boolean,
      then: (schema) => schema.required("required").min(1),
      otherwise: (schema) => schema.nullable()
    }),
  emails: mixed()
    .transform((value) => {
      return value.split(",").map((v) => v.trim())
    })
    .test("emailString", "should be email", (value) => {
      return array(string().email()).isValidSync(value)
    })
    .when("extending", {
      is: Boolean,
      then: array().required("required"),
      otherwise: array().nullable()
    })
})

export const getSelectedAddOns = ({ product, formik }) => {
  return product.add_ons.filter((addOn) => {
    const selectedProductAddOns = formik.values.addOns[product.id]
    if (!selectedProductAddOns) {
      return false
    }
    return selectedProductAddOns.includes(addOn.id)
  })
}

const dynamicFieldSchema = object({
  name: string().required("required"),
  technical_name: string().required("required"),
  is_mandatory: boolean(),
  count: mixed().when("selected", {
    is: true,
    then: number().typeError("number").required("required")
  }),
  length: mixed().when("selected", {
    is: true,
    then: number().typeError("number").required("required")
  }),
  not_removable: boolean(),
  selected: boolean()
})

export const dynamicFieldsSchema = object({
  dynamic_fields: array().of(dynamicFieldSchema)
})

export const getDiscountPrice = ({ product, showPackagePrice }) =>
  showPackagePrice
    ? product.calculated_single_package_price
    : product.customer_price ?? product.market_price

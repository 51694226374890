import React from "react"
import { I18n, Translate } from "react-redux-i18n"
import { Field, Formik } from "formik"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Stack,
  Typography
} from "@mui/material"

import { Dialog } from "shared/ui/berry-jass"
import {
  ActivityEventForm,
  activityEventSchema,
  TasksTable
} from "entities/activity-event"
import { TasksRemoteAutocomplete } from "entities/activities-task"
import { getInitialValues } from "../lib"
import {
  closeDialog,
  useHandleCreateActivityEvent,
  useStore,
  setTasks
} from "../model"
import { RemoteAutocomplete } from "shared/ui/berry-jass-formik"

const FORM_ID = "activity-event-create-form"

export const CreateActivityEventDialog = ({ setCustomer }) => {
  const { open, customer, tasks, showCustomer } = useStore()
  const handleCreateActivityEvent = useHandleCreateActivityEvent(customer?.id)
  const initialValues = getInitialValues(customer)

  if (!open) {
    return null
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={activityEventSchema}
      onSubmit={handleCreateActivityEvent}
      render={({ isSubmitting, handleSubmit, setFieldValue, values }) => {
        const { task_ids: taskValues } = values

        const handleRemoveTask = (task) => {
          const tasks = taskValues.filter((val) => val.id !== task.id)

          setFieldValue("task_ids", tasks)
          setTasks(tasks)
        }

        return (
          <Dialog
            open={open}
            onClose={closeDialog}
            data-testid="create-activity-event-dialog"
            maxWidth="lg"
            titleSx={{ pb: 2 }}
            title={<Translate value="activity-events.create-event" />}
          >
            <DialogContent
              dividers
              sx={{
                pt: 3,
                maxWidth: 624
              }}
            >
              <form
                id={FORM_ID}
                onSubmit={(e) => {
                  e.stopPropagation()
                  handleSubmit(e)
                }}
              >
                <Stack spacing={2}>
                  {showCustomer && (
                    <Field
                      fullWidth
                      name="jap_customer_id"
                      component={RemoteAutocomplete}
                      paginated
                      id="jap_customer_id"
                      size="small"
                      data-testid="customer-select"
                      label={
                        <Translate value="activities-center.filters.customer" />
                      }
                      mapResponseToDataSource={(response) =>
                        response?.data ?? []
                      }
                      onChange={(_e, value) => {
                        setCustomer?.(value)
                      }}
                      url="/autocomplete/jap_customers"
                      params={{
                        q: { type_in: ["EndCustomer", "PartnerCustomer"] }
                      }}
                      variant="ransack"
                      searchProp="q.customer_number_or_company_name_cont"
                      getOptionLabel={(customer) =>
                        `${customer.customer_number} | ${customer.company_name}`
                      }
                    />
                  )}

                  <ActivityEventForm />
                </Stack>
              </form>
              <Box sx={{ pt: 2 }}>
                <TasksRemoteAutocomplete
                  value={tasks}
                  japCustomerId={customer?.id}
                  placeholder={I18n.t("activity-events.add-todos")}
                  onChange={(e, value) => setTasks(value)}
                  onClose={() => setFieldValue("task_ids", tasks)}
                />
                {!!taskValues.length && (
                  <Typography sx={{ fontSize: 14, fontWeight: 500, mt: 2 }}>
                    <Translate value="activity-events.associated-todos" />
                  </Typography>
                )}

                <TasksTable
                  customerId={customer?.id}
                  taskIds={taskValues.map((val) => val.id)}
                  onRemoveTask={handleRemoveTask}
                />
              </Box>
            </DialogContent>

            <DialogActions sx={{ pt: 2 }}>
              <Button onClick={closeDialog} variant="outlined">
                <Translate value="actions.cancel" />
              </Button>
              <LoadingButton
                form={FORM_ID}
                loading={isSubmitting}
                type="submit"
                variant="contained"
              >
                <Translate value="save" />
              </LoadingButton>
            </DialogActions>
          </Dialog>
        )
      }}
    />
  )
}

import React from "react"
import { I18n, Translate } from "react-redux-i18n"
import { Field, Formik } from "formik"
import { LoadingButton } from "@mui/lab"
import {
  Button,
  DialogActions,
  DialogContent,
  Stack,
  Typography
} from "@mui/material"

import { Dialog } from "shared/ui/berry-jass"
import { RemoteAutocomplete } from "shared/ui/berry-jass-formik"
import {
  ActivityNoteForm,
  activityNoteSchema,
  TasksTable
} from "entities/activity-note"
import { TasksRemoteAutocomplete } from "entities/activities-task"
import { getInitialValues } from "../lib"
import {
  closeDialog,
  useHandleCreateActivityNote,
  useStore,
  setTasks
} from "../model"

const FORM_ID = "activity-note-create-form"

export const CreateActivityNoteDialog = ({ setCustomer }) => {
  const { open, japCustomer, tasks, showCustomer } = useStore()
  const handleCreateActivityNote = useHandleCreateActivityNote(japCustomer?.id)
  const initialValues = getInitialValues(japCustomer)

  if (!open) {
    return null
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={activityNoteSchema}
      onSubmit={handleCreateActivityNote}
      render={({ isSubmitting, handleSubmit, setFieldValue, values }) => {
        const { task_ids: taskValues } = values

        const handleRemoveTask = (task) => {
          const tasks = taskValues.filter((val) => val.id !== task.id)

          setFieldValue("task_ids", tasks)
          setTasks(tasks)
        }

        return (
          <Dialog
            open={open}
            onClose={closeDialog}
            data-testid="create-activity-note-dialog"
            maxWidth="lg"
            titleSx={{ pb: 2 }}
            title={<Translate value="activity-notes.create-note" />}
          >
            <DialogContent
              dividers
              sx={{
                pt: 3,
                maxWidth: 624
              }}
            >
              <Stack spacing={2}>
                {showCustomer && (
                  <Field
                    fullWidth
                    name="jap_customer_id"
                    component={RemoteAutocomplete}
                    paginated
                    id="jap_customer_id"
                    size="small"
                    data-testid="customer-select"
                    label={
                      <Translate value="activities-center.filters.customer" />
                    }
                    mapResponseToDataSource={(response) => response?.data ?? []}
                    onChange={(_e, value) => {
                      setCustomer?.(value)
                    }}
                    url="/autocomplete/jap_customers"
                    params={{
                      q: { type_in: ["EndCustomer", "PartnerCustomer"] }
                    }}
                    variant="ransack"
                    searchProp="q.customer_number_or_company_name_cont"
                    getOptionLabel={(customer) =>
                      `${customer.customer_number} | ${customer.company_name}`
                    }
                  />
                )}

                <TasksRemoteAutocomplete
                  value={tasks}
                  size="small"
                  japCustomerId={japCustomer?.id}
                  placeholder={I18n.t("activity-notes.add-todos")}
                  onChange={(e, value) => setTasks(value)}
                  onClose={() => setFieldValue("task_ids", tasks)}
                />
              </Stack>

              <form
                id={FORM_ID}
                onSubmit={(e) => {
                  e.stopPropagation()
                  handleSubmit(e)
                }}
              >
                <ActivityNoteForm />
              </form>

              {!!taskValues.length && (
                <Typography sx={{ fontSize: 14, fontWeight: 500, mt: 2 }}>
                  <Translate value="activity-notes.associated-todos" />
                </Typography>
              )}

              <TasksTable
                customerId={japCustomer?.id}
                taskIds={taskValues.map((val) => val.id)}
                onRemoveTask={handleRemoveTask}
              />
            </DialogContent>

            <DialogActions sx={{ pt: 2 }}>
              <Button onClick={closeDialog} variant="outlined">
                <Translate value="actions.cancel" />
              </Button>
              <LoadingButton
                form={FORM_ID}
                loading={isSubmitting}
                type="submit"
                variant="contained"
              >
                <Translate value="save" />
              </LoadingButton>
            </DialogActions>
          </Dialog>
        )
      }}
    />
  )
}

import { useTodoTasks } from "entities/activities-task"

const getTodoTasksFilterParams = (japCustomerId, taskIds = [], eventId) => {
  return {
    q: {
      id_in: taskIds,
      events_id_eq: eventId,
      jap_customer_id_eq: japCustomerId,
      sorts: "due_date asc"
    }
  }
}

export const useFilteredTodoTasks = (japCustomerId, taskIds = [], eventId) => {
  return useTodoTasks(
    getTodoTasksFilterParams(japCustomerId, taskIds, eventId),
    {
      enabled: !!taskIds.length || !!eventId
    }
  )
}

import {
  useMutation,
  useQuery,
  useQueryClient,
  useInfiniteQuery
} from "@tanstack/react-query"

import client from "utils/client"
import { getNextPageParam } from "shared/lib/ransack"

export const useContact = (id) => {
  return useQuery({
    queryKey: ["v2_contacts", "show", id],
    queryFn: async () => {
      const res = await client(`/v2/contacts/${id}.json`)
      return res.data
    },
    enabled: !!id
  })
}

export const useContacts = (params = {}) => {
  return useInfiniteQuery({
    queryKey: ["v2_contacts", "index", params],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await client.get("/v2/contacts.json", {
        params: { ...params, page: pageParam }
      })
      return res.data
    },
    getNextPageParam
  })
}

export const useCreateContact = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ values, account_id, customer_id }) => {
      const response = await client.post("/v2/contacts.json", {
        contact: values,
        account_id,
        customer_id
      })

      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["v2_contacts", "index"] })
    }
  })
}

export const useUpdateContact = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ id, values }) => {
      const response = await client.put(`/v2/contacts/${id}.json`, {
        contact: values
      })

      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["v2_contacts", "index"] })
      queryClient.removeQueries({ queryKey: ["v2_contacts", "show"] })
    }
  })
}

export const useDeleteContact = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (id) => {
      const response = await client.delete(`/v2/contacts/${id}.json`)

      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["v2_contacts", "index"] })
    }
  })
}

import moment from "moment"

export const getInitialValues = (japCustomer, contact) => {
  return {
    title: "",
    description: "",
    due_date: moment().add(1, "days").endOf("day").toDate(),
    jap_customer_id: japCustomer ?? null,
    contact_id: contact ?? null,
    reminder: null,
    attachments: []
  }
}

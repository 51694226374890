import React from "react"
import { I18n, Translate } from "react-redux-i18n"
import { Formik } from "formik"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Typography
} from "@mui/material"

import { BackdropLoader, Dialog } from "shared/ui/berry-jass"
import {
  ActivityEventForm,
  activityEventSchema,
  TasksTable,
  useActivityEvent
} from "entities/activity-event"
import { TasksRemoteAutocomplete } from "entities/activities-task"
import { getInitialValues } from "../lib"
import {
  closeDialog,
  useHandleUpdateActivityEvent,
  useStore,
  setTasks
} from "../model"

const FORM_ID = "activity-event-edit-form"

export const EditActivityEventDialog = () => {
  const { open, activityEvent: event, tasks } = useStore()
  const { data: activityEvent, isInitialLoading } = useActivityEvent(
    event?.id,
    {
      onSuccess: (event) => setTasks(event.tasks)
    }
  )
  const handleUpdateActivityEvent = useHandleUpdateActivityEvent(event?.id)

  if (!open) {
    return null
  }

  if (isInitialLoading) {
    return <BackdropLoader />
  }

  const initialValues = getInitialValues(activityEvent, {
    id: event?.jap_customer_id
  })

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={activityEventSchema}
      onSubmit={handleUpdateActivityEvent}
      render={({ isSubmitting, handleSubmit, setFieldValue, values }) => {
        const { task_ids: taskValues } = values

        const handleRemoveTask = (task) => {
          const tasks = taskValues.filter((val) => val.id !== task.id)

          setFieldValue("task_ids", tasks)
          setTasks(tasks)
        }

        return (
          <Dialog
            open={open}
            onClose={closeDialog}
            data-testid="edit-activity-event-dialog"
            maxWidth="lg"
            titleSx={{ pb: 2 }}
            title={<Translate value="activity-events.edit-event" />}
          >
            <DialogContent
              dividers
              sx={{
                pt: 3,
                maxWidth: 624
              }}
            >
              <form
                id={FORM_ID}
                onSubmit={(e) => {
                  e.stopPropagation()
                  handleSubmit(e)
                }}
              >
                <ActivityEventForm />
              </form>

              {!!taskValues.length && (
                <Typography sx={{ fontSize: 14, fontWeight: 500, mt: 2 }}>
                  <Translate value="activity-events.associated-todos" />
                </Typography>
              )}

              <Box sx={{ pt: 2 }}>
                <TasksRemoteAutocomplete
                  value={tasks}
                  japCustomerId={event?.jap_customer_id}
                  placeholder={I18n.t("activity-events.add-todos")}
                  onChange={(e, value) => setTasks(value)}
                  onClose={() => setFieldValue("task_ids", tasks)}
                />

                <TasksTable
                  customerId={event?.jap_customer_id}
                  taskIds={taskValues.map((val) => val.id)}
                  onRemoveTask={handleRemoveTask}
                />
              </Box>
            </DialogContent>

            <DialogActions sx={{ pt: 2 }}>
              <Button onClick={closeDialog} variant="outlined">
                <Translate value="actions.cancel" />
              </Button>
              <LoadingButton
                form={FORM_ID}
                loading={isSubmitting}
                type="submit"
                variant="contained"
              >
                <Translate value="save" />
              </LoadingButton>
            </DialogActions>
          </Dialog>
        )
      }}
    />
  )
}

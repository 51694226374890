import React, { useCallback } from "react"
import { Box } from "@mui/material"

import { ExpandCell } from "shared/ui/berry-jass"
import { getTableProps } from "shared/lib/ransack"
import { COLUMN_WIDTH_AUTO } from "shared"
import {
  completedColumn,
  getTitleColumn,
  expandColumn
} from "entities/activities-task"
import { ActivityNoteCell } from "entities/activity-note"
import { ActivityEventCell } from "entities/activity-event"
import { ActivityTasksActionsCell } from "../ui/ActivityTasksActionsCell"
import { ActivityEventsActionsCell } from "../ui/ActivityEventsActionsCell"
import { ActivityNotesActionsCell } from "../ui/ActivityNotesActionsCell"

export const getActivityTasksColumns = (showCustomer) => [
  completedColumn,
  getTitleColumn(showCustomer),
  expandColumn,
  {
    id: "actions",
    size: 50,
    height: 65,
    cell: (info) => (
      <ActivityTasksActionsCell id={info.cell.id} task={info.row.original} />
    )
  }
]

const getActivityNotesColumns = (customerId, showCustomer) => [
  {
    accessorKey: "creator",
    size: COLUMN_WIDTH_AUTO,
    meta: { cellSx: { pr: 0 } },
    cell: (info) => (
      <ActivityNoteCell
        showCustomer={showCustomer}
        activity={info.row.original}
      />
    )
  },
  {
    id: "expand",
    size: 50,
    cell: (info) =>
      info.row.getCanExpand() ? <ExpandCell row={info.row} /> : null
  },
  {
    id: "actions",
    size: 50,
    height: 65,
    cell: (info) => (
      <ActivityNotesActionsCell
        id={info.cell.id}
        note={info.row.original}
        japCustomerId={customerId}
      />
    )
  }
]

export const getActivityEventsColumns = (customerId, showCustomer) => [
  {
    accessorKey: "title",
    size: COLUMN_WIDTH_AUTO,
    meta: { cellSx: { pr: 0 } },
    cell: (info) => (
      <ActivityEventCell
        showCustomer={showCustomer}
        activity={info.row.original}
      />
    )
  },
  {
    id: "expand",
    size: 50,
    cell: (info) =>
      info.row.getCanExpand() ? (
        <Box position="absolute">
          <ExpandCell row={info.row} />
        </Box>
      ) : null
  },
  {
    id: "actions",
    size: 50,
    height: 65,
    cell: (info) => (
      <Box position="absolute">
        <ActivityEventsActionsCell
          id={info.cell.id}
          activityEvent={info.row.original}
          japCustomerId={customerId}
        />
      </Box>
    )
  }
]

export const useTodosTableProps = (showCustomer) => {
  return useCallback(
    (query) => {
      const props = getTableProps(query)
      return { ...props, columns: getActivityTasksColumns(showCustomer) }
    },
    [showCustomer]
  )
}

export const useActivityNotesTableProps = (customerId, showCustomer) => {
  return useCallback(
    (query) => {
      const props = getTableProps(query)
      return {
        ...props,
        columns: getActivityNotesColumns(customerId, showCustomer)
      }
    },
    [customerId, showCustomer]
  )
}

export const useActivityEventsTableProps = (customerId, showCustomer) => {
  return useCallback(
    (query) => {
      const props = getTableProps(query)
      return {
        ...props,
        columns: getActivityEventsColumns(customerId, showCustomer)
      }
    },
    [customerId, showCustomer]
  )
}

import React from "react"
import { I18n, Translate } from "react-redux-i18n"
import { Formik } from "formik"
import { LoadingButton } from "@mui/lab"
import { Button, DialogActions, DialogContent, Typography } from "@mui/material"

import { BackdropLoader, Dialog } from "shared/ui/berry-jass"
import {
  ActivityNoteForm,
  editActivityNoteSchema,
  TasksTable,
  useActivityNote
} from "entities/activity-note"
import { TasksRemoteAutocomplete } from "entities/activities-task"
import { getInitialValues } from "../lib"
import {
  closeDialog,
  useHandleUpdateActivityNote,
  useStore,
  setTasks
} from "../model"

const FORM_ID = "activity-note-edit-form"

export const EditActivityNoteDialog = () => {
  const { open, activityNote: note, tasks } = useStore()
  const { data: activityNote, isInitialLoading } = useActivityNote(note?.id, {
    onSuccess: (note) => setTasks(note.tasks)
  })
  const handleUpdateActivityNote = useHandleUpdateActivityNote(note?.id)

  if (!open) {
    return null
  }

  if (isInitialLoading) {
    return <BackdropLoader />
  }

  const initialValues = getInitialValues(activityNote)

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={editActivityNoteSchema}
      onSubmit={handleUpdateActivityNote}
      render={({ isSubmitting, handleSubmit, setFieldValue, values }) => {
        const { task_ids: taskValues } = values

        const handleRemoveTask = (task) => {
          const tasks = taskValues.filter((val) => val.id !== task.id)

          setFieldValue("task_ids", tasks)
          setTasks(tasks)
        }

        return (
          <Dialog
            open={open}
            onClose={closeDialog}
            data-testid="edit-activity-note-dialog"
            maxWidth="lg"
            titleSx={{ pb: 2 }}
            title={<Translate value="activity-notes.edit-note" />}
          >
            <DialogContent
              dividers
              sx={{
                pt: 3,
                maxWidth: 624
              }}
            >
              <TasksRemoteAutocomplete
                value={tasks}
                japCustomerId={note?.jap_customer_id}
                placeholder={I18n.t("activity-notes.add-todos")}
                onChange={(e, value) => setTasks(value)}
                onClose={() => setFieldValue("task_ids", tasks)}
              />

              <form
                id={FORM_ID}
                onSubmit={(e) => {
                  e.stopPropagation()
                  handleSubmit(e)
                }}
              >
                <ActivityNoteForm />
              </form>

              {!!taskValues.length && (
                <Typography sx={{ fontSize: 14, fontWeight: 500, mt: 2 }}>
                  <Translate value="activity-notes.associated-todos" />
                </Typography>
              )}

              <TasksTable
                customerId={note?.jap_customer_id}
                taskIds={taskValues.map((val) => val.id)}
                onRemoveTask={handleRemoveTask}
              />
            </DialogContent>

            <DialogActions sx={{ pt: 2 }}>
              <Button onClick={closeDialog} variant="outlined">
                <Translate value="actions.cancel" />
              </Button>
              <LoadingButton
                form={FORM_ID}
                loading={isSubmitting}
                type="submit"
                variant="contained"
              >
                <Translate value="save" />
              </LoadingButton>
            </DialogActions>
          </Dialog>
        )
      }}
    />
  )
}

import useHandleServerErrors from "hooks/useHandleServerErrors"
import {
  activityEventSchema,
  useCreateActivityEvent
} from "entities/activity-event"
import { closeDialog } from "./store"

export const useHandleCreateActivityEvent = (jap_customer_id) => {
  const createActivityEvent = useCreateActivityEvent()
  const handleServerErrors = useHandleServerErrors()

  return (values, formik) => {
    createActivityEvent.mutate(
      activityEventSchema.cast({ jap_customer_id, ...values }),
      {
        onSuccess() {
          formik.resetForm()
          closeDialog()
        },
        onError: (error) => handleServerErrors(error),
        onSettled() {
          formik.setSubmitting(false)
        }
      }
    )
  }
}

import React, { useCallback } from "react"
import { Translate } from "react-redux-i18n"
import { openDialog } from "./store"

export const useEditTaskAction = () => {
  return useCallback((task) => {
    return {
      label: <Translate value="edit" />,
      onClick: () => openDialog(task)
    }
  }, [])
}

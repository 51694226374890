import moment from "moment"

export const getInitialValues = (customer) => {
  return {
    title: "",
    description: "",
    location: "",
    start_at: moment().add(1, "days").startOf("day"),
    finish_at: moment().add(1, "days").add(30, "minutes"),
    jap_customer_id: customer ?? null,
    contact_id: null,
    task_ids: []
  }
}

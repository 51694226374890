import React, { useCallback } from "react"
import { Translate } from "react-redux-i18n"

import { useHandleDeleteActivityNote } from "./handlers"

export const useDeleteActivityNoteAction = () => {
  const handleDeleteActivityNote = useHandleDeleteActivityNote()

  return useCallback(
    (activityNote) => {
      return {
        label: <Translate value="delete.title" />,
        onClick: () => handleDeleteActivityNote(activityNote)
      }
    },
    [handleDeleteActivityNote]
  )
}

import moment from "moment"
import { I18n } from "react-redux-i18n"
import { object, string, array, mixed, date, ref } from "yup"

export const activityEventSchema = object({
  title: string().required("required"),
  description: string(),
  location: string(),
  jap_customer_id: mixed()
    .required("required")
    .transform((japCustomer) => japCustomer?.id),
  contact_id: mixed().transform((contact) => contact?.id),
  start_at: date()
    .required("required")
    .min(moment().startOf("day").toDate(), I18n.t("errors.date-in-past")),
  finish_at: date()
    .required("required")
    .min(ref("start_at"), I18n.t("errors.must-be-greater-than-start-date")),
  task_ids: array().of(mixed().transform((value) => value?.id))
})

import React, { memo } from "react"
import { Box, Typography } from "@mui/material"
import { Translate } from "react-redux-i18n"
import objectHash from "object-hash"

import { getTableMeta } from "shared/lib/ransack"
import { DataTable } from "shared/ui/berry-jass-extended"
import { borderlessTableSx, Wysiwyg } from "shared/ui/berry-jass"
import { TasksTable } from "entities/activity-note"
import { useFilteredActivityNotes, useActivityNotesTableProps } from "../model"

const ActivityNotesTableComponent = ({ showCustomer, useFilters, user }) => {
  const { japCustomer } = useFilters()
  const activityNotes = useFilteredActivityNotes(useFilters, user)
  const meta = getTableMeta(activityNotes)
  const getTableProps = useActivityNotesTableProps(
    japCustomer?.id,
    showCustomer
  )

  return (
    <DataTable
      size="small"
      hideHeader
      meta={meta}
      sx={borderlessTableSx}
      emptyStateProps={{
        sx: { mt: 2, pb: 2 },
        text: <Translate value="activities.no-activity-notes" />,
        subText: <Translate value="activities.create-activity-notes" />
      }}
      renderSubComponent={({ row }) => {
        const { id, text, with_todo_tasks } = row.original
        const key = objectHash({ id, text }) // to rerender Wysiwyg on update

        return (
          <Box sx={{ py: 0, pl: 2 }}>
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 400
              }}
            >
              <Wysiwyg // workaround to display text with formatting
                disabled
                toolbarHidden
                key={key}
                value={text}
                sx={{
                  border: "unset",
                  "& .rdw-editor-main": { minHeight: "unset", p: 0 }
                }}
              />
            </Typography>

            {with_todo_tasks && (
              <TasksTable customerId={japCustomer?.id} noteId={id} />
            )}
          </Box>
        )
      }}
      getRowCanExpand={() => true}
      {...getTableProps(activityNotes)}
    />
  )
}

export const ActivityNotesTable = memo(ActivityNotesTableComponent)

import React from "react"
import { Field, connect } from "formik"
import { Translate } from "react-redux-i18n"
import { Grid, FormControlLabel } from "@mui/material"

import {
  Autocomplete,
  RemoteAutocomplete,
  TextField,
  Checkbox
} from "shared/ui/berry-jass-formik"
import { useCountries, formatCountry } from "entities/country"
import { getCityProps, getZipProps } from "entities/postal-codes-service"
import { getGenders, getSalutations, isSalutationCustom } from "entities/user"

export const ContactForm = connect(({ formik }) => {
  const { values, initialValues } = formik
  const genders = getGenders()
  const salutations = getSalutations()
  const selectedCountry = values.country?.id
  const cityProps = getCityProps({
    country: selectedCountry,
    zipCode: values.zip?.zip_code ?? values.zip
  })
  const zipProps = getZipProps({
    country: selectedCountry,
    city: values.city?.city ?? values.city
  })

  const { data: countries, isInitialLoading } = useCountries()

  const handleGenderChange = (e, value) => {
    if (!value || isSalutationCustom(values.salutation)) return

    const salutation = salutations.find(
      (salutation) => salutation.id === value.id
    )?.name

    if (salutation) {
      formik.setFieldValue("salutation", salutation)
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item md={12}>
        <Field
          required
          fullWidth
          id="company"
          name="company"
          component={TextField}
          label={<Translate value="contacts.form.company" />}
        />
      </Grid>
      <Grid item md={12}>
        <Field
          fullWidth
          id="department"
          name="department"
          component={TextField}
          label={<Translate value="contacts.form.department" />}
        />
      </Grid>
      <Grid item md={2}>
        <Field
          fullWidth
          id="title"
          name="title"
          component={TextField}
          label={<Translate value="contacts.form.title" />}
        />
      </Grid>
      <Grid item md={4}>
        <Field
          required
          fullWidth
          id="first_name"
          name="first_name"
          component={TextField}
          label={<Translate value="contacts.form.first-name" />}
        />
      </Grid>
      <Grid item md={6}>
        <Field
          required
          fullWidth
          id="last_name"
          name="last_name"
          component={TextField}
          label={<Translate value="contacts.form.last-name" />}
        />
      </Grid>
      <Grid item md={6}>
        <Field
          required
          disableClearable
          id="gender"
          name="gender"
          component={Autocomplete}
          options={genders}
          label={<Translate value="contacts.form.gender" />}
          onChange={handleGenderChange}
        />
      </Grid>
      <Grid item md={6}>
        <Field
          required
          fullWidth
          id="salutation"
          name="salutation"
          component={TextField}
          label={<Translate value="contacts.form.salutation" />}
        />
      </Grid>
      <Grid item md={12}>
        <Field
          required
          fullWidth
          id="email"
          name="email"
          component={TextField}
          label={<Translate value="contacts.form.email" />}
        />
      </Grid>
      <Grid item md={12}>
        <Field
          required
          fullWidth
          id="phone"
          name="phone"
          component={TextField}
          label={<Translate value="contacts.form.phone" />}
        />
      </Grid>
      <Grid item md={12}>
        <Field
          fullWidth
          multiline
          minRows={4}
          id="notes"
          name="notes"
          component={TextField}
          label={<Translate value="contacts.form.notes" />}
        />
      </Grid>
      <Grid item md={12}>
        <Field
          required
          fullWidth
          id="country"
          name="country"
          component={Autocomplete}
          getOptionLabel={formatCountry}
          loading={isInitialLoading}
          options={countries}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          label={<Translate value="contacts.form.country" />}
        />
      </Grid>
      <Grid item md={6}>
        <Field
          required
          fullWidth
          id="zip"
          name="zip"
          label={<Translate value="contacts.form.zip" />}
          component={RemoteAutocomplete}
          {...zipProps}
        />
      </Grid>
      <Grid item md={6}>
        <Field
          required
          fullWidth
          id="city"
          name="city"
          label={<Translate value="contacts.form.city" />}
          component={RemoteAutocomplete}
          {...cityProps}
        />
      </Grid>
      <Grid item md={6}>
        <Field
          required
          fullWidth
          id="street"
          name="street"
          component={TextField}
          label={<Translate value="contacts.form.street" />}
        />
      </Grid>
      <Grid item md={6}>
        <Field
          required
          fullWidth
          id="street_number"
          name="street_number"
          component={TextField}
          label={<Translate value="contacts.form.street-number" />}
        />
      </Grid>
      <Grid item md={12}>
        <FormControlLabel
          label={<Translate value="contacts.form.is-default" />}
          control={
            <Field
              disabled={initialValues.is_default}
              name="is_default"
              component={Checkbox}
              color="secondary"
            />
          }
        />
      </Grid>
    </Grid>
  )
})

import moment from "moment"

export const getInitialValues = (task) => {
  return {
    title: task?.title ?? "",
    description: task?.description ?? "",
    due_date: moment(task?.due_date).endOf("day").toDate(),
    jap_customer_id: task?.jap_customer_id ? task.jap_customer : null,
    contact_id: task?.contact_id ? task.contact : null,
    reminder: task?.reminder
      ? { id: task?.reminder.id, time: task?.reminder.time }
      : null,
    attachments: task?.attachments ?? []
  }
}

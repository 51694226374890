import React from "react"
import { Translate } from "react-redux-i18n"
import { Field, Form } from "formik"
import { Button, Stack, Typography } from "@mui/material"

import { WhiteLabelLoginCard } from "shared/ui/berry-jass"
import { TextField } from "shared/ui/berry-jass-formik"

export const ForgotPasswordForm = () => {
  return (
    <WhiteLabelLoginCard component={Form}>
      <Stack gap={1}>
        <Typography variant="h2">
          <Translate value="forgot_password.title" />
        </Typography>
      </Stack>

      <Typography variant="h4" fontWeight="normal">
        <Translate value="forgot_password.description" />
      </Typography>

      <Field
        fullWidth
        name="email"
        component={TextField}
        label={<Translate value="forgot_password.email-address" />}
      />

      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ textTransform: "unset" }}
      >
        <Translate value="forgot_password.submit" />
      </Button>
    </WhiteLabelLoginCard>
  )
}

import React, { memo } from "react"
import { I18n, Translate } from "react-redux-i18n"
import { Box, Typography } from "@mui/material"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import moment from "moment"

import { CustomerPerformanceLink } from "entities/activity"

export const ActivityNoteCell = memo(({ activity, showCustomer }) => {
  const { creator, created_at, with_todo_tasks, all_todo_tasks_done } = activity

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
      }}
    >
      <Box
        sx={{
          display: "inline-flex",
          alignItems: "center"
        }}
      >
        <Typography sx={{ fontSize: 14, fontWeight: 500 }} component="span">
          <Translate value="activities.note-by" name={creator.name} />
        </Typography>

        {all_todo_tasks_done && (
          <CheckCircleIcon
            sx={{
              fontSize: 14,
              fontWeight: 400,
              color: "primary.main",
              ml: 0.5
            }}
          />
        )}
      </Box>

      {with_todo_tasks && !all_todo_tasks_done && (
        <Box>
          <Typography
            sx={{ fontSize: 12, fontWeight: 400, color: "primary.main" }}
          >
            <Translate value="activities.associated-todos-present" />
          </Typography>
        </Box>
      )}

      {showCustomer && <CustomerPerformanceLink activity={activity} />}

      <Box>
        <Typography sx={{ fontSize: 12, fontWeight: 400 }}>
          <Translate
            value="activities.created-at"
            date={moment(created_at).format(
              I18n.t("time.formats.weekday-month-time")
            )}
          />
        </Typography>
      </Box>
    </Box>
  )
})

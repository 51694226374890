import React, { useState } from "react"
import { I18n } from "react-redux-i18n"
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import { TextField } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { DesktopDateTimePicker } from "@mui/x-date-pickers"

/**
 *
 * @param {Omit<import('@mui/x-date-pickers').DesktopDateTimePickerProps, 'renderInput' | 'onChange'> & {sx?: import('@mui/material').SxProps, error?: boolean, name?: string, clearable?: boolean, id?: string, textField?: import("@mui/material").TextFieldProps, InputLabelProps?: import('@mui/material').InputLabelProps, onChange?: (value: any, keyboardInputValue?: string) => void; size?: import("@mui/material").TextFieldProps['size']}} props
 * @returns
 */
export const DateTimePicker = ({
  value,
  error,
  onChange = () => {},
  onAccept,
  onClose,
  id,
  label,
  name,
  clearable,
  sx,
  dateTimeSx = {},
  InputLabelProps,
  textField = {},
  size = "medium",
  ...rest
}) => {
  const [open, setOpen] = useState(false)

  const handleAccept = (val) => {
    if (onAccept && !isNaN(val)) {
      onAccept(val)
    }

    setOpen(false)
  }

  const handleClose = () => {
    onClose?.()
    setOpen(false)
  }

  return (
    <DesktopDateTimePicker
      {...rest}
      sx={{
        "& .MuiPickersToolbar-penIconButton": {
          position: "static",
          top: "unset",
          right: "unset"
        },
        ...dateTimeSx
      }}
      open={open}
      closeOnSelect
      showToolbar
      toolbarTitle={I18n.t("select-datetime")}
      toolbarFormat={I18n.t("time.formats.day-month")}
      value={value}
      onChange={onChange}
      onAccept={handleAccept}
      onClose={handleClose}
      PopperProps={{ id: "date-picker" }}
      renderInput={(props) => {
        return (
          <TextField
            fullWidth
            {...props}
            size={size}
            sx={sx}
            id={id}
            name={name}
            label={label}
            error={error ?? (!!value && props.error)}
            {...textField}
            onClick={() => setOpen(true)}
            onBlur={(e) => {
              props.onBlur?.(e)
              if (onAccept && !isNaN(value)) {
                onAccept(value)
              }
            }}
            InputLabelProps={InputLabelProps}
            InputProps={{
              ...rest.InputProps,
              endAdornment:
                clearable && value ? (
                  <>
                    <InputAdornment position="end">
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation()
                          onChange(null)
                          onAccept?.(null)
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                    {rest.InputProps?.endAdornment}
                    {props.InputProps?.endAdornment}
                  </>
                ) : (
                  <>
                    {rest.InputProps?.endAdornment}
                    {props.InputProps.endAdornment}
                  </>
                )
            }}
          />
        )
      }}
    />
  )
}

import { proxy, useSnapshot } from "valtio"

const store = proxy({ task: null, open: false })

export const openDialog = (task) => {
  store.open = true
  store.task = task
}

export const closeDialog = () => {
  store.open = false
  store.task = null
}

export const useStore = () => {
  return useSnapshot(store)
}

import React, { Component } from "react"
import { connect } from "react-redux"
import { I18n } from "react-redux-i18n"
import Button from "@mui/material/Button"
import Snackbar from "@mui/material/Snackbar"
import DialogActions from "@mui/material/DialogActions"
import Translate from "components/Translate/Translate"
import { Dialog } from "components"
import { remove } from "store/modules/common/notify"
import withStyles from "@mui/styles/withStyles"
import Alert from "@mui/material/Alert"
import isArray from "lodash/isArray"
import map from "lodash/map"
import noop from "lodash/noop"
import get from "lodash/get"
import clone from "lodash/clone"
import kebabCase from "lodash/kebabCase"
import size from "lodash/size"
import indexOf from "lodash/indexOf"
import uniqWith from "lodash/uniqWith"
import compact from "lodash/compact"
import { DialogContent, Typography } from "@mui/material"

import { blue, green } from "@mui/material/colors"

const autoHideDuration = 6000

class Notify extends Component {
  constructor(props) {
    super(props)
    this.renderDialog = this.renderDialog.bind(this)
  }

  hideNotifyHandler(id) {
    return isArray(id)
      ? Promise.all(map(id, this.props.remove))
      : this.props.remove(id)
  }

  renderDialog({
    notifications = [],
    onClose = noop,
    onOk = noop,
    showCancel = false,
    confirmBtnText = <Translate value="actions.ok" />,
    cancelBtnText = <Translate value="actions.cancel" />
  }) {
    return (
      <Dialog
        onClose={onClose}
        open
        style={{ zIndex: 3000 }}
        maxWidth="sm"
        hideTitle
      >
        {uniqWith(notifications, (notification) => notification.message).map(
          (notify) => {
            return (
              <DialogContent
                key={`notify-message-${notify.id}`}
                className={`errorMessageRow ${get(notify, "className")}`}
                style={{ paddingLeft: 30, paddingTop: 45 }}
              >
                <Typography gutterBottom variant="h6">
                  {notify.message}
                </Typography>
              </DialogContent>
            )
          }
        )}
        <DialogActions>
          <Button variant="text" color="secondary" onClick={onOk}>
            {confirmBtnText}
          </Button>
          {showCancel && (
            <Button variant="text" color="secondary" onClick={onClose}>
              {cancelBtnText}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )
  }

  render() {
    const notifyList = clone(this.props.notifyList)

    const notifications = compact(
      notifyList.map((notify) => {
        const i18nKey = get(notify.message, "i18n")
        const kebab = get(notify.message, "kebab", true)
        const message = i18nKey
          ? I18n.t(kebab ? kebabCase(i18nKey) : i18nKey)
          : notify.message

        return message ? { ...notify, message } : null
      })
    )

    if (!size(notifications)) {
      return null
    }

    const snackbarNotifications = notifications.filter(
      (n) => indexOf(["success", "info", "error", "warning"], n.type) > -1
    )

    const confirmationNotify = notifications.filter(
      (n) => n.type === "confirmation"
    )?.[0]

    const onCloseConfirm = confirmationNotify
      ? this.hideNotifyHandler.bind(this, confirmationNotify.id)
      : noop

    return (
      <div className="text-center">
        {!!size(confirmationNotify) &&
          this.renderDialog({
            notifications: [confirmationNotify],
            onClose: () => {
              onCloseConfirm()
              confirmationNotify?.onCancel?.()
            },
            onOk: () => {
              onCloseConfirm()
              get(confirmationNotify, "onConfirm", noop)()
            },
            showCancel: get(confirmationNotify, "showCancel", true),
            confirmBtnText: confirmationNotify.confirmBtnText,
            cancelBtnText: confirmationNotify.cancelBtnText
          })}

        {snackbarNotifications.map((notification, index) => (
          <Snackbar
            key={`notify-message notify-message-${notification.id}`}
            open={true}
            autoHideDuration={
              notification.type === "error" || notification.disableAutoHide
                ? null
                : autoHideDuration
            }
            onClose={(event, reason) => {
              if (reason === "clickaway" && notification.disableClickAway) {
                return
              }

              this.hideNotifyHandler(notification.id)
            }}
            anchorOrigin={{
              vertical: notification.position ?? "top",
              horizontal: "center"
            }}
            action={notification.action}
            style={{
              bottom: notification.showMultiple ? `${20 + index * 60}px` : null
            }}
          >
            <Alert
              severity={notification.type}
              onClose={() => {
                this.hideNotifyHandler(notification.id)

                if (notification.onClose) {
                  notification.onClose()
                }
              }}
            >
              {notification.component ?? notification.message}
            </Alert>
          </Snackbar>
        ))}
      </div>
    )
  }
}

const styles = () => ({
  info: {
    backgroundColor: blue[600]
  },
  success: {
    backgroundColor: green[600]
  }
})

export default connect(
  (state) => ({
    notifyList: state.notify.list
  }),
  { remove }
)(withStyles(styles)(Notify))

import { taskSchema, useCreateTask } from "entities/activities-task"
import { closeDialog } from "./store"
import useHandleServerErrors from "hooks/useHandleServerErrors"

export const useHandleCreateTask = (jap_customer_id) => {
  const createTask = useCreateTask()
  const handleServerErrors = useHandleServerErrors()

  return (values, formik) => {
    createTask.mutate(taskSchema.cast({ jap_customer_id, ...values }), {
      onSuccess() {
        formik.resetForm()
        if (values.submitter !== "create_and_new") closeDialog()
      },
      onError: (error) => handleServerErrors(error),
      onSettled() {
        formik.setSubmitting(false)
      }
    })
  }
}

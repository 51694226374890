import { I18n } from "react-redux-i18n"
import moment from "moment"

export const getLanguages = () => [
  { id: "en", name: I18n.t("app.langs.en") },
  { id: "de", name: I18n.t("app.langs.de") }
]

export const getGenders = () => [
  { id: "male", name: I18n.t("app.genders.male") },
  { id: "female", name: I18n.t("app.genders.female") },
  { id: "unisex", name: I18n.t("app.genders.unisex") }
]

export const getSalutations = () => [
  { id: "male", name: I18n.t("app.salutations.male") },
  { id: "female", name: I18n.t("app.salutations.female") },
  { id: "unisex", name: I18n.t("app.salutations.unisex") }
]

export const isSalutationCustom = (value) => {
  return value && !Object.values(I18n.t("app.salutations")).includes(value)
}

export const getInitialLoginValues = () => {
  return { email: "", password: "" }
}

export const getInitialChangePasswordValues = ({
  passwordToken,
  confirmationToken
}) => {
  return {
    current_password: "",
    password: "",
    password_confirmation: "",
    reset_password_token: passwordToken,
    confirmation_token: confirmationToken
  }
}

export const getInitialRequestChangePasswordValues = () => {
  return { email: "" }
}

export const formatCompanyName = (companyName) => companyName || "-"

export const formatLastLogin = (date) =>
  date
    ? moment(date).format("DD.MM.YYYY — HH:mm:ss")
    : I18n.t("users.last-login.never")

export const isEndCustomerUser = (user) =>
  user.account_owner_type === "end_customer" &&
  user.jap_customer?.type === "EndCustomer"

export const isAgencyCustomerUser = (user) =>
  user.account_owner_type === "end_customer" &&
  user.jap_customer?.type === "AgencyCustomer"

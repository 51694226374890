import React from "react"
import { Field } from "formik"
import { Grid } from "@mui/material"

import { Wysiwyg } from "shared/ui/berry-jass-formik"

export const ActivityNoteForm = () => {
  return (
    <Grid container spacing={3}>
      <Grid item md={12}>
        <Field fullWidth multiline rows={4} component={Wysiwyg} name="text" />
      </Grid>
    </Grid>
  )
}

import { useCallback } from "react"

import { taskSchema, useUpdateTask } from "entities/activities-task"
import { useDeleteAttachment } from "entities/activities-attachment"
import { closeDialog, useStore } from "./store"
import useHandleServerErrors from "hooks/useHandleServerErrors"

export const useHandleUpdateTask = () => {
  const { task } = useStore()
  const updateTask = useUpdateTask()
  const handleServerErrors = useHandleServerErrors()

  return (values, formik) => {
    updateTask.mutate(
      { id: task.id, ...taskSchema.cast(values) },
      {
        onSuccess() {
          formik.resetForm()
          closeDialog()
        },
        onError: (error) => handleServerErrors(error),
        onSettled() {
          formik.setSubmitting(false)
        }
      }
    )
  }
}

export const useHandleDeleteAttachment = () => {
  const deleteAttachment = useDeleteAttachment()
  const handleServerErrors = useHandleServerErrors()

  return useCallback(
    (file) => {
      if (!file.id) {
        return
      }

      deleteAttachment.mutate(file.id, {
        onError: (error) => handleServerErrors(error)
      })
    },
    [deleteAttachment, handleServerErrors]
  )
}

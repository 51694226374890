import useHandleServerErrors from "hooks/useHandleServerErrors"
import {
  activityEventSchema,
  useUpdateActivityEvent
} from "entities/activity-event"
import { closeDialog } from "./store"

export const useHandleUpdateActivityEvent = (id) => {
  const updateActivityEvent = useUpdateActivityEvent()
  const handleServerErrors = useHandleServerErrors()

  return (values, formik) => {
    updateActivityEvent.mutate(activityEventSchema.cast({ id, ...values }), {
      onSuccess() {
        formik.resetForm()
        closeDialog()
      },
      onError: (error) => handleServerErrors(error),
      onSettled() {
        formik.setSubmitting(false)
      }
    })
  }
}

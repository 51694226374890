import React from "react"
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography
} from "@mui/material"
import isNil from "lodash/isNil"
import { filesize } from "filesize"
import AttachmentIcon from "@mui/icons-material/Attachment"
import DownloadIcon from "@mui/icons-material/Download"

export const TasksTableSubComponent = ({ row }) => {
  const { description, attachments } = row.original

  return (
    <>
      <Box sx={{ py: 0, pl: 2 }}>
        <Typography sx={{ whiteSpace: "pre-line" }}>{description}</Typography>
      </Box>
      {!!attachments?.length && (
        <List
          sx={{ display: "flex", flexDirection: "column", gap: 1.5, pl: 2 }}
        >
          {attachments.map((attachment) => {
            return (
              <ListItem
                key={attachment.id}
                sx={{
                  padding: "0.5rem !important",
                  border: "1px solid",
                  borderRadius: "0.5rem",
                  borderColor: "#bdbdbd",
                  backgroundColor: "#FAFAFA"
                }}
              >
                <ListItemIcon>
                  <AttachmentIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Stack>
                      <Box
                        sx={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          maxWidth: "75%",
                          fontWeight: 500,
                          fontSize: 12
                        }}
                      >
                        {attachment.name}
                      </Box>
                      {!isNil(attachment.size) && (
                        <Typography fontSize={10}>
                          {filesize(attachment.size ?? 0)}
                        </Typography>
                      )}
                    </Stack>
                  }
                />
                <ListItemSecondaryAction>
                  <Stack direction="row" gap={2}>
                    <IconButton
                      size="small"
                      onClick={() => window.open(attachment.url, "_blank")}
                    >
                      <DownloadIcon fontSize="1rem" />
                    </IconButton>
                  </Stack>
                </ListItemSecondaryAction>
              </ListItem>
            )
          })}
        </List>
      )}
    </>
  )
}

import React from "react"
import { I18n } from "react-redux-i18n"
import CloseIcon from "@mui/icons-material/Close"
import { IconButton } from "@mui/material"

export const TaskRemoveAction = ({ task, onRemoveTask }) => {
  return (
    <IconButton
      size="medium"
      sx={{ p: "9px" }}
      onClick={() => onRemoveTask(task)}
      title={I18n.t("remove")}
    >
      <CloseIcon
        sx={{
          fontSize: 16
        }}
      />
    </IconButton>
  )
}

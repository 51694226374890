import { proxy, useSnapshot } from "valtio"

const store = proxy({
  japCustomer: null,
  contact: null,
  open: false,
  showCustomer: false
})

export const openDialog = (customer, contact = null, showCustomer = false) => {
  store.open = true
  store.japCustomer = customer
  store.contact = contact
  store.showCustomer = showCustomer
}

export const closeDialog = () => {
  store.open = false
  store.japCustomer = null
  store.contact = null
  store.showCustomer = false
}

export const useStore = () => {
  return useSnapshot(store)
}

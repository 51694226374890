import useHandleServerErrors from "hooks/useHandleServerErrors"
import { useUpdateReminder } from "../api"
import React, { useCallback } from "react"
import { Link } from "react-router-dom"
import { Translate } from "react-redux-i18n"

import { useWebSockets } from "shared/hooks"
import useUser from "hooks/useUser"
import useNotifications from "hooks/useNotifications"
import { openDialog } from "features/activity-task-edit"

export const useHandleUpdateReminder = () => {
  const { mutate } = useUpdateReminder()
  const handleServerErrors = useHandleServerErrors()

  return useCallback(
    (id) => mutate(id, { onError: (error) => handleServerErrors(error) }),
    [mutate, handleServerErrors]
  )
}

export const useShowActivityReminderNotification = () => {
  const { showInfo } = useNotifications()
  const handleUpdateReminder = useHandleUpdateReminder()

  return useCallback(
    ({ id, task_id, task_title, jap_customer_id, jap_customer_type }) => {
      const routeByType = {
        PartnerCustomer: `/r/partner-manager/customers/${jap_customer_id}/performance`,
        EndCustomer: `/r/customer-manager/end-customers/${jap_customer_id}/performance`
      }

      showInfo("Reminder", {
        showMultiple: true,
        disableAutoHide: true,
        disableClickAway: true,
        position: "bottom",
        onClose: () => handleUpdateReminder(id),
        component: (
          <>
            <Translate value="activities.reminder-notification" />
            <Link
              data-testid={`reminder-task-${task_id}`}
              to={routeByType[jap_customer_type]}
              onClick={() => openDialog({ id: task_id })}
            >
              {task_title}
            </Link>
          </>
        )
      })
    },
    [showInfo, handleUpdateReminder]
  )
}

export const useSubscribeToActivitiesReminders = () => {
  const user = useUser()
  const showActivityReminderNotification = useShowActivityReminderNotification()

  useWebSockets(
    `activities.task.remind.user.${user?.id}`,
    "Reminders",
    useCallback(
      (event) => showActivityReminderNotification(event.data),
      [showActivityReminderNotification]
    )
  )
}

import moment from "moment"
import { I18n } from "react-redux-i18n"
import { date, object, string, mixed, array } from "yup"

export const taskSchema = object({
  title: string().required("required"),
  description: string().nullable(),
  jap_customer_id: mixed()
    .required("required")
    .transform((japCustomer) => japCustomer?.id),
  contact_id: mixed().transform((contact) => contact?.id),
  due_date: date()
    .required("required")
    .min(moment().startOf("day").toDate(), I18n.t("errors.date-in-past"))
    .required("required"),
  reminder: object({
    time: date()
      .test(
        "is-before-due-date",
        I18n.t("errors.must-be-before-due-date"),
        function validateReminder(value) {
          const dueDate = this.from[1]?.value?.due_date

          if (!dueDate || !value) return true

          return moment(value).isSameOrBefore(moment(dueDate).endOf("day"))
        }
      )
      .nullable()
  }).nullable(),
  attachments: array().of(mixed())
})

export const ATTACHMENT_MIME_TYPES = {
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx"
  ],
  "application/vnd.ms-excel": [".xls", ".csv"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx"
  ],
  "application/pdf": [".pdf"],
  "image/jpeg": [".jpg", ".jpeg"],
  "image/png": [".png"],
  "image/gif": [".gif"]
}

import { mixed, object, string, boolean } from "yup"

export const contactSchema = object({
  company: string().required("required"),
  department: string().nullable(),
  title: string().nullable(),
  first_name: string().required("required"),
  last_name: string().required("required"),
  gender: mixed()
    .transform((value) => value?.id)
    .oneOf(["male", "female", "unisex"])
    .required("required"),
  salutation: string().nullable().required("required"),
  email: string().required("required").email("email"),
  phone: string().required("required"),
  notes: string().nullable(),
  street: string().required("required"),
  street_number: string().required("required"),
  country: mixed()
    .transform((value) => value?.id)
    .required("required"),
  zip: mixed()
    .transform((value) => value?.zip_code)
    .nullable()
    .required("required"),
  city: mixed()
    .transform((value) => value?.city)
    .nullable()
    .required("required"),
  is_default: boolean()
})

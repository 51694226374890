import React from "react"
import { Box, Typography } from "@mui/material"

import { DataTable } from "shared/ui/berry-jass-extended"
import { useFilteredTodoTasks, useTodosTableProps } from "../model"
import { borderlessTableSx } from "shared/ui/berry-jass"

const TasksTableComponent = ({
  customerId,
  taskIds,
  eventId,
  onRemoveTask,
  ...rest
}) => {
  const tasks = useFilteredTodoTasks(customerId, taskIds, eventId)
  const getTableProps = useTodosTableProps(onRemoveTask)

  return (
    <DataTable
      size="small"
      hideHeader
      sx={borderlessTableSx}
      renderSubComponent={({ row }) => {
        const task = row.original
        return (
          <Box sx={{ py: 0, pl: "16px" }}>
            <Typography sx={{ whiteSpace: "pre-line" }}>
              {task.description}
            </Typography>
          </Box>
        )
      }}
      getRowCanExpand={(row) => {
        const task = row.original
        return !!task.description
      }}
      {...getTableProps(tasks)}
      {...rest}
    />
  )
}

export const TasksTable = React.memo(TasksTableComponent)

import React, { memo } from "react"
import { I18n } from "react-redux-i18n"
import { Divider, Stack } from "@mui/material"

import { HorizontalStack } from "shared/ui/berry-jass"
import { SearchInput } from "shared/ui/berry-jass-extended"
import { CreateActivityNoteFab } from "features/activity-note-create"

export const ActivityNotesFilters = memo(
  ({ showCustomer, useFilters, setSearchActivityNote }) => {
    const { searchActivityNote, japCustomer } = useFilters()

    return (
      <Stack>
        <HorizontalStack gap={1}>
          <SearchInput
            fullWidth
            id="search"
            initialValue={searchActivityNote}
            onChange={setSearchActivityNote}
            size="small"
            placeholder={I18n.t("search.title")}
          />
          <CreateActivityNoteFab
            showCustomer={showCustomer}
            customer={japCustomer}
            sx={{ p: 2 }}
          />
        </HorizontalStack>

        <Divider variant="fullWidth" sx={{ pb: 2 }} />
      </Stack>
    )
  }
)

import React from "react"
import { Translate } from "react-redux-i18n"
import { ErrorMessage, Field, Form } from "formik"
import { Link as RouterLink } from "react-router-dom"
import { LoadingButton } from "@mui/lab"
import { Link, Stack, Typography } from "@mui/material"

import { PasswordTextField, TextField } from "shared/ui/berry-jass-formik"
import { WhiteLabelLoginCard } from "shared/ui/berry-jass"

export const LoginForm = ({ isSubmitting }) => {
  return (
    <WhiteLabelLoginCard component={Form}>
      <Stack gap={1}>
        <Typography variant="h2">
          <Translate value="login.welcome" />
        </Typography>
        <Typography variant="h4" fontWeight="normal">
          <Translate value="login.description" />
        </Typography>
      </Stack>
      <Field
        fullWidth
        name="email"
        component={TextField}
        label={<Translate value="login.email-address" />}
      />
      <Field
        fullWidth
        name="password"
        component={PasswordTextField}
        label={<Translate value="login.password" />}
      />
      <Link
        to="/r/forgot"
        component={RouterLink}
        color="secondary"
        sx={{ alignSelf: "flex-start" }}
      >
        <Typography fontWeight={500}>
          <Translate value="login.forgot-password" />
        </Typography>
      </Link>
      <ErrorMessage name="base" />

      <LoadingButton
        variant="contained"
        color="primary"
        type="submit"
        loading={isSubmitting}
        sx={{ textTransform: "unset" }}
      >
        <Translate value="login.submit" />
      </LoadingButton>
    </WhiteLabelLoginCard>
  )
}

import React, { memo } from "react"

import { ActionsCell } from "shared/ui/berry-jass"
import { useEditActivityNoteAction } from "features/activity-note-edit"
import { useDeleteActivityNoteAction } from "features/activity-note-delete"

export const ActivityNotesActionsCell = memo(({ id, note, japCustomerId }) => {
  const editActivityNoteAction = useEditActivityNoteAction()
  const deleteActivityNoteAction = useDeleteActivityNoteAction()

  const actions = [
    editActivityNoteAction(note, japCustomerId),
    deleteActivityNoteAction(note)
  ]

  return <ActionsCell id={id} actions={actions} />
})

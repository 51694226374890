import React from "react"
import { Box } from "@mui/material"

import { COLUMN_WIDTH_AUTO } from "shared"
import { ExpandCell } from "shared/ui/berry-jass"
import { DoneCell, ActivityTaskCell } from "../ui"

export const completedColumn = {
  id: "completed",
  size: 45,
  cell: (info) => {
    const task = info.row.original
    return (
      <Box sx={{ ml: "-12px" }}>
        <DoneCell task={task} />
      </Box>
    )
  }
}

export const getTitleColumn = (showCustomer = false) => ({
  accessorKey: "title",
  size: COLUMN_WIDTH_AUTO,
  cell: (info) => (
    <ActivityTaskCell
      showCustomer={showCustomer}
      activity={info.row.original}
    />
  )
})

export const expandColumn = {
  id: "expand",
  size: 50,
  cell: (info) =>
    info.row.getCanExpand() ? <ExpandCell row={info.row} /> : null
}

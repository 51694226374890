import { I18n } from "react-redux-i18n"
import groupBy from "lodash/groupBy"
import moment from "moment"

export const contactAdditionalOptions = [{ id: "0", name: "—" }]

export const defaultGroupByOption = () => ({ id: "day", name: I18n.t("day") })

export const getGroupByOptions = () => {
  return [
    defaultGroupByOption(),
    { id: "week", name: I18n.t("week") },
    { id: "month", name: I18n.t("month") }
  ]
}

const getIsOverdue = (feedItem) => {
  const {
    feed_itemable_type,
    feed_itemable: { done, due_date }
  } = feedItem

  return (
    feed_itemable_type === "Activities::Task" &&
    !done &&
    moment(due_date).isBefore(moment(), "day")
  )
}

const getDateByType = (feedItem) => {
  const {
    feed_itemable_type,
    feed_itemable: { due_date, start_at }
  } = feedItem

  if (feed_itemable_type === "Activities::Task") {
    return due_date
  }

  if (feed_itemable_type === "Activities::Event") {
    return start_at
  }

  return null
}

export const getGroupedFeedItems = (groupedBy, allFeedItems) =>
  groupBy(allFeedItems, (feedItem) => {
    if (getIsOverdue(feedItem)) {
      return I18n.t("activities.overdue")
    }

    switch (groupedBy.id) {
      case "day":
        return moment(getDateByType(feedItem)).format("YYYY-MM-DD")
      case "week":
        return moment(getDateByType(feedItem))
          .startOf("isoWeek")
          .format("YYYY-[W]WW")
      case "month":
        return moment(getDateByType(feedItem)).format("YYYY-MM")
      default:
        return "Other"
    }
  })

export const getSortedGroupKeys = (groupedFeedItems) =>
  Object.keys(groupedFeedItems).sort((a, b) => {
    if (a === I18n.t("activities.overdue")) return -1
    if (b === I18n.t("activities.overdue")) return 1

    return a.localeCompare(b)
  })

export const formatDateByGroup = (groupedBy, group) => {
  if (group === I18n.t("activities.overdue")) {
    return { left: I18n.t("activities.overdue") }
  }

  switch (groupedBy.id) {
    case "day":
      return {
        left: moment(group).format(I18n.t("time.formats.weekday-month")),
        right: moment(group).format("YYYY")
      }
    case "week":
      return {
        left: moment(group).format(
          `[${I18n.t("activities.week-number-format")}]WW`
        ),
        right: moment(group).format("YYYY")
      }
    case "month":
      return {
        left: moment(group).format("MMMM"),
        right: moment(group).format("YYYY")
      }
    default:
      return group
  }
}

export const getPerformanceUrl = ({ jap_customer_id, customer_type, user }) => {
  const performanceUrl = {
    EndCustomer: user.isCustomerManager
      ? `/r/customer-manager/end-customers/${jap_customer_id}/performance`
      : null,
    PartnerCustomer:
      user.isPartnerCustomerManager || user.isPartnerManager
        ? `/r/partner-manager/customers/${jap_customer_id}/performance`
        : null
  }[customer_type]

  return performanceUrl
}

import { useCallback } from "react"
import { I18n } from "react-redux-i18n"
import useConfirm from "hooks/useConfirm"
import { useDeleteTask } from "entities/activities-task"
import useHandleServerErrors from "hooks/useHandleServerErrors"

export const useHandleDeleteTask = () => {
  const deleteTask = useDeleteTask()
  const handleServerErrors = useHandleServerErrors()
  const confirm = useConfirm()

  return useCallback(
    (task) => {
      confirm(I18n.t("delete.sure", { entity: task.title }), {
        title: I18n.t("activities.delete-todo"),
        onConfirm: () => {
          deleteTask.mutate(task.id, {
            onError: (error) => handleServerErrors(error)
          })
        },
        confirmBtnText: I18n.t("delete.title"),
        buttonSize: "large"
      })
    },
    [confirm, deleteTask, handleServerErrors]
  )
}

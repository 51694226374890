import React, { memo } from "react"
import { I18n } from "react-redux-i18n"
import { Fab } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import { openDialog } from "../model"

export const CreateTodoTaskFab = memo(
  ({ customer, contact, showCustomer, ...rest }) => {
    return (
      <Fab
        color="primary"
        size="small"
        onClick={() => openDialog(customer, contact, showCustomer)}
        title={I18n.t("activities.create-task")}
        {...rest}
      >
        <AddIcon sx={{ fontSize: 16 }} />
      </Fab>
    )
  }
)

import React, { useCallback } from "react"
import { Translate } from "react-redux-i18n"
import { openDialog } from "./store"

export const useEditActivityNoteAction = () => {
  return useCallback((activityNote, japCustomerId) => {
    return {
      label: <Translate value="edit" />,
      onClick: () => openDialog({ activityNote, japCustomerId })
    }
  }, [])
}

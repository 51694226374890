import React, { memo, useRef } from "react"
import { I18n, Translate } from "react-redux-i18n"
import {
  Box,
  Divider,
  LinearProgress,
  Skeleton,
  Stack,
  TableContainer,
  Typography
} from "@mui/material"

import {
  borderlessTableSx,
  Autocomplete,
  HorizontalStack,
  borderlessTableWithRelativeTrSx
} from "shared/ui/berry-jass"
import {
  DataTable,
  EmptyState,
  SearchInput
} from "shared/ui/berry-jass-extended"
import { TasksTableSubComponent } from "entities/activities-task"
import { ActivityEventsTableSubComponent } from "entities/activity-event"
import { useInfiniteActivityFeedItems } from "entities/activity-feed-item"
import { getActivityEventsColumns, getActivityTasksColumns } from "../model"
import {
  formatDateByGroup,
  getGroupByOptions,
  getGroupedFeedItems,
  getSortedGroupKeys
} from "../lib"

const ActivityTasksTableComponent = memo(({ activity, showCustomer }) => {
  return (
    <DataTable
      size="small"
      hideHeader
      data={[activity]}
      columns={getActivityTasksColumns(showCustomer)}
      sx={borderlessTableSx}
      renderSubComponent={TasksTableSubComponent}
      getRowCanExpand={(row) => {
        const task = row.original
        return !!task.description || !!task.attachments.length
      }}
    />
  )
})

const ActivityEventsTableComponent = memo(
  ({ activity, customerId, showCustomer }) => {
    return (
      <DataTable
        size="small"
        hideHeader
        data={[activity]}
        columns={getActivityEventsColumns(customerId, showCustomer)}
        sx={borderlessTableWithRelativeTrSx}
        renderSubComponent={({ row }) => (
          <ActivityEventsTableSubComponent row={row} customerId={customerId} />
        )}
        getRowCanExpand={(row) => {
          const { contact, with_todo_tasks, description } = row.original

          return !!contact || with_todo_tasks || !!description
        }}
      />
    )
  }
)

const COMPONENTS_BY_TYPE = {
  "Activities::Task": ActivityTasksTableComponent,
  "Activities::Event": ActivityEventsTableComponent
}

const ActivityFeedComponent = ({
  showCustomer,
  useFilters,
  user,
  setGroupedBy,
  setSearchActivityFeedItem
}) => {
  const { japCustomer, searchActivityFeedItem, groupedBy } = useFilters()
  const {
    query: feedItems,
    ref,
    showLoading
  } = useInfiniteActivityFeedItems({
    customerId: japCustomer?.id,
    user,
    search: searchActivityFeedItem
  })
  const groupByRef = useRef(null)
  const allFeedItems = feedItems.data?.pages.flatMap((page) => page.data) || []
  const groupedFeedItems = getGroupedFeedItems(groupedBy, allFeedItems)
  const sortedGroupKeys = getSortedGroupKeys(groupedFeedItems)

  return (
    <Box
      sx={{
        maxHeight: "100%",
        position: "relative",
        overflow: "hidden"
      }}
    >
      <Stack ref={groupByRef}>
        <SearchInput
          fullWidth
          id="search"
          initialValue={searchActivityFeedItem}
          onChange={setSearchActivityFeedItem}
          size="small"
          placeholder={I18n.t("search.title")}
        />

        <Autocomplete
          id="group"
          size="small"
          fullWidth
          disableClearable
          sx={{
            pt: 1,
            pb: 2
          }}
          label={<Translate value="activities.group-by" />}
          onChange={(_e, value) => setGroupedBy(value)}
          options={getGroupByOptions()}
          value={groupedBy}
        />

        <Divider variant="fullWidth" />
      </Stack>

      <TableContainer
        sx={{
          maxHeight: `calc(100% - ${groupByRef.current?.clientHeight ?? 0}px)`
        }}
      >
        {feedItems.isInitialLoading && (
          <Skeleton variant="rectangular" height="100vh" />
        )}

        {!feedItems.isInitialLoading && !allFeedItems.length && (
          <EmptyState
            sx={{ mt: 2 }}
            text={<Translate value="activities.no-upcoming-activities" />}
          />
        )}

        {!feedItems.isInitialLoading &&
          sortedGroupKeys.map((groupKey) => {
            const date = formatDateByGroup(groupedBy, groupKey)

            return (
              <React.Fragment key={groupKey}>
                <HorizontalStack justifyContent="space-between">
                  <Typography variant="h5" sx={{ mt: 2, mb: 0, pl: 0 }}>
                    {date.left}
                  </Typography>

                  <Typography variant="h5" sx={{ mt: 2, mb: 0, pl: 2 }}>
                    {date.right}
                  </Typography>
                </HorizontalStack>

                {groupedFeedItems[groupKey].map((feedItem) => {
                  const Component =
                    COMPONENTS_BY_TYPE[feedItem.feed_itemable_type]

                  return (
                    <Box
                      key={feedItem.id}
                      sx={{
                        my: 1,
                        py: 1,
                        pr: 1,
                        border: "1px solid #DFDFDF",
                        borderRadius: "0.5rem"
                      }}
                    >
                      <Component
                        showCustomer={showCustomer}
                        customerId={japCustomer?.id}
                        activity={feedItem.feed_itemable}
                      />
                    </Box>
                  )
                })}
              </React.Fragment>
            )
          })}

        {showLoading && <LinearProgress ref={ref} />}
      </TableContainer>
    </Box>
  )
}

export const ActivityFeed = memo(ActivityFeedComponent)

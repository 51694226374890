import { object, string, ref } from "yup"

export const loginSchema = object({
  email: string().required("required").email("email"),
  password: string().required("required")
})

export const changePasswordSchema = object({
  current_password: string().nullable(),
  password: string().required("required"),
  password_confirmation: string()
    .required("required")
    .oneOf([ref("password"), null], "match"),
  reset_password_token: string().nullable(),
  confirmation_token: string().nullable()
})

export const forgotPasswordSchema = object({
  email: string().required("required").email("email")
})

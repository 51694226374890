import React, { memo } from "react"
import { Stack, Typography, Badge } from "@mui/material"
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle"

export const ContactCell = memo(({ contact }) => {
  const fullName = `${contact.first_name} ${contact.last_name}`

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Badge
        variant="dot"
        color="secondary"
        overlap="circular"
        invisible={!contact.is_default}
      >
        <PersonPinCircleIcon />
      </Badge>
      <Stack>
        <Typography variant="h6">{contact.company}</Typography>
        <Typography variant="h6" fontWeight="400">
          {fullName}
        </Typography>
      </Stack>
    </Stack>
  )
})

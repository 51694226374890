import React, { memo } from "react"

import { ActionsCell } from "shared/ui/berry-jass"
import { useEditActivityEventAction } from "features/activity-event-edit"
import { useDeleteActivityEventAction } from "features/activity-event-delete"

export const ActivityEventsActionsCell = memo(
  ({ id, activityEvent, japCustomerId }) => {
    const editActivityEventAction = useEditActivityEventAction()
    const deleteActivityEventAction = useDeleteActivityEventAction()

    const actions = [
      editActivityEventAction(activityEvent, japCustomerId),
      deleteActivityEventAction(activityEvent)
    ]

    return <ActionsCell id={id} actions={actions} />
  }
)

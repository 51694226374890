import { getGenders } from "entities/user"

export const getInitialValues = (contact) => {
  const genders = getGenders()
  let gender
  let country
  let zip
  let city

  if (typeof contact?.gender === "string") {
    gender = genders.find((gender) => gender.id === contact.gender)
  } else {
    gender = contact?.gender
  }

  if (typeof contact?.country === "string") {
    country = { id: contact.country, name: contact.country.toUpperCase() }
  } else {
    country = contact?.country
  }

  if (typeof contact?.zip === "string") {
    zip = { zip_code: contact.zip }
  } else {
    zip = contact?.zip
  }

  if (typeof contact?.city === "string") {
    city = { city: contact.city }
  } else {
    city = contact?.city
  }

  return {
    company: contact?.company ?? "",
    department: contact?.department ?? "",
    title: contact?.title ?? "",
    first_name: contact?.first_name ?? "",
    last_name: contact?.last_name ?? "",
    gender: contact?.gender ? gender : null,
    salutation: contact?.salutation ?? "",
    email: contact?.email ?? "",
    phone: contact?.phone ?? "",
    notes: contact?.notes ?? "",
    street: contact?.street ?? "",
    street_number: contact?.street_number ?? "",
    country: contact?.country ? country : null,
    zip: contact?.zip ? zip : null,
    city: contact?.country ? city : null,
    is_default: contact?.is_default ?? false
  }
}

/** @param {{is_default: boolean}[]} data */
export const findDefaultContact = (data) => {
  return data.find((d) => d.is_default) ?? null
}

export const getAlternativeContactsOptionLabel = (option) =>
  option.id === "0"
    ? " - "
    : `${option.first_name} ${option.last_name}, ${option.company}, ${option.street} ${option.street_number}, ${option.zip} ${option.city}`

import { useTodoTasks } from "entities/activities-task"

const getTodoTasksFilterParams = (japCustomerId, taskIds = [], noteId) => {
  return {
    q: {
      id_in: taskIds,
      notes_id_eq: noteId,
      jap_customer_id_eq: japCustomerId,
      sorts: "due_date asc"
    }
  }
}

export const useFilteredTodoTasks = (japCustomerId, taskIds = [], noteId) => {
  return useTodoTasks(
    getTodoTasksFilterParams(japCustomerId, taskIds, noteId),
    {
      enabled: !!taskIds.length || !!noteId
    }
  )
}

import { useCallback } from "react"
import { I18n } from "react-redux-i18n"

import useConfirm from "hooks/useConfirm"
import { useDeleteActivityEvent } from "entities/activity-event"
import useHandleServerErrors from "hooks/useHandleServerErrors"

export const useHandleDeleteActivityEvent = () => {
  const deleteActivityEvent = useDeleteActivityEvent()
  const handleServerErrors = useHandleServerErrors()
  const confirm = useConfirm()

  return useCallback(
    (activityEvent) => {
      confirm(
        I18n.t("activity-events.delete.sure", {
          entity: activityEvent.title
        }),
        {
          title: I18n.t("activities.delete-event"),
          onConfirm: () => {
            deleteActivityEvent.mutate(activityEvent.id, {
              onError: (error) => handleServerErrors(error)
            })
          },
          confirmBtnText: I18n.t("delete.title"),
          buttonSize: "large"
        }
      )
    },
    [confirm, deleteActivityEvent, handleServerErrors]
  )
}

import { Box, useTheme } from "@mui/material"
import React from "react"
import Avatar from "shared/ui/berry-jass/Avatar"

export const AvatarPreview = ({ value }) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: "inline-block",
        minWidth: "100%",
        aspectRatio: "1",
        position: "relative",
        img: { height: "100%" },
        overflow: "hidden",
        marginLeft: 1.5
      }}
    >
      <Avatar
        src={value.thumb_url || value.url}
        size="xl"
        sx={{
          borderStyle: "solid",
          borderColor: theme.palette.grey[400]
        }}
      />
    </Box>
  )
}

import React from "react"
import { Checkbox } from "@mui/material"
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

import { useUpdateTask } from "entities/activities-task"

export const DoneCell = ({ task }) => {
  const updateTask = useUpdateTask()

  return (
    <Checkbox
      icon={<RadioButtonUncheckedIcon />}
      checkedIcon={<CheckCircleIcon />}
      name={`todo-task-${task.id}`}
      sx={{ fontSize: 20 }}
      checked={task.done}
      onChange={(e, value) => {
        updateTask.mutate({ id: task.id, done: value })
      }}
    />
  )
}

import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { Alert, Snackbar } from "@mui/material"

import * as cookies from "shared/lib/cookies"
import { remove, useSnackbarNotifications } from "store/modules/common/notify"
import useHandleServerErrors from "hooks/useHandleServerErrors"

const autoHideDuration = 6000

export const Notifications = () => {
  const notifications = useSnackbarNotifications()
  const dispatch = useDispatch()
  const handleServerErrors = useHandleServerErrors()

  useEffect(() => {
    cookies.pop("errors", (errors) => {
      if (errors) {
        handleServerErrors({
          response: { data: { errors: JSON.parse(errors) } }
        })
      }
    })
  }, [handleServerErrors])

  const handleClose = (notificationId) => {
    dispatch(remove(notificationId))
  }

  return (
    <>
      {notifications.map((notification, index) => (
        <Snackbar
          key={`notify-message notify-message-${notification.id}`}
          open={true}
          autoHideDuration={
            notification.type === "error" || notification.disableAutoHide
              ? null
              : autoHideDuration
          }
          onClose={(event, reason) => {
            if (reason === "clickaway" && notification.disableClickAway) return

            if (notification.type !== "error") {
              handleClose(notification.id)
            }
          }}
          anchorOrigin={{
            vertical: notification.position ?? "top",
            horizontal: "center"
          }}
          action={notification.action}
          style={{
            bottom: notification.showMultiple ? `${20 + index * 60}px` : null
          }}
        >
          <Alert
            severity={notification.type}
            onClose={() => {
              handleClose(notification.id)

              if (notification.onClose) {
                notification.onClose()
              }
            }}
            sx={{ whiteSpace: "pre-line" }}
          >
            {notification.component ?? notification.message}
          </Alert>
        </Snackbar>
      ))}
    </>
  )
}

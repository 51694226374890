import React from "react"
import { Translate } from "react-redux-i18n"

import { getTableMeta } from "shared/lib/ransack"
import { DataTable } from "shared/ui/berry-jass-extended"
import { borderlessTableSx } from "shared/ui/berry-jass"
import { TasksTableSubComponent } from "entities/activities-task"
import { useFilteredTodoTasks, useTodosTableProps } from "../model"

const ActivityTasksTableComponent = ({ showCustomer, useFilters, user }) => {
  const tasks = useFilteredTodoTasks(useFilters, user)
  const meta = getTableMeta(tasks)
  const getTableProps = useTodosTableProps(showCustomer)

  return (
    <DataTable
      size="small"
      hideHeader
      meta={meta}
      sx={borderlessTableSx}
      emptyStateProps={{
        sx: { mt: 2, pb: 2 },
        text: <Translate value="activities.no-tasks" />,
        subText: <Translate value="activities.no-description" />
      }}
      renderSubComponent={TasksTableSubComponent}
      getRowCanExpand={(row) => {
        const task = row.original
        return !!task.description || !!task.attachments.length
      }}
      {...getTableProps(tasks)}
    />
  )
}

export const ActivityTasksTable = React.memo(ActivityTasksTableComponent)

import { useMutation, useQueryClient } from "@tanstack/react-query"
import client from "utils/client"

export const useDeleteAttachment = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (id) => {
      const response = await client.delete(
        `/v2/activities/attachments/${id}.json`
      )

      return response.data
    },

    onSuccess: () => {
      queryClient.invalidateQueries(["v2", "activities", "tasks"])
    }
  })
}

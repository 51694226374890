import React from "react"
import { Checkbox } from "@mui/material"

import { RemoteAutocomplete } from "shared"

export const TasksRemoteAutocomplete = ({
  japCustomerId: jap_customer_id_eq,
  value,
  ...props
}) => {
  return (
    <RemoteAutocomplete
      multiple
      paginated
      disableCloseOnSelect
      id="tasks-search"
      name="task"
      variant="ransack"
      searchProp="q.title_cont"
      sx={{ pb: 2 }}
      mapResponseToDataSource={(response) => response?.data ?? []}
      url={"/autocomplete/activities/tasks.json"}
      params={{
        q: {
          jap_customer_id_eq,
          sorts: "title asc"
        }
      }}
      value={value}
      renderTags={() => null}
      renderOption={(props, option, { selected }) => {
        const { key, ...optionProps } = props

        return (
          <li
            key={option.id}
            {...optionProps}
            aria-selected="false" // disable background highlight
          >
            <Checkbox checked={selected} />
            {option.name}
          </li>
        )
      }}
      {...props}
    />
  )
}

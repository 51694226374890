import { useQuery } from "@tanstack/react-query"

import client from "utils/client"

export const useSalesProductsDynamicFields = (
  salesProductIds,
  jap_customer_id,
  config
) => {
  return useQuery({
    queryKey: ["salesProducts", "dynamicFields", salesProductIds],
    queryFn: async () => {
      const res = await client.get("/sales_products/dynamic_fields.json", {
        params: {
          sales_product_ids: salesProductIds,
          jap_customer_id
        }
      })

      return res.data
    },
    ...config
  })
}

import React, { useCallback } from "react"
import { Translate } from "react-redux-i18n"

import { useHandleDeleteActivityEvent } from "./handlers"

export const useDeleteActivityEventAction = () => {
  const handleDeleteActivityEvent = useHandleDeleteActivityEvent()

  return useCallback(
    (activityEvent) => {
      return {
        label: <Translate value="delete.title" />,
        onClick: () => handleDeleteActivityEvent(activityEvent)
      }
    },
    [handleDeleteActivityEvent]
  )
}

import moment from "moment"

export const getInitialValues = (activityEvent, customer) => {
  return {
    title: activityEvent?.title ?? "",
    description: activityEvent?.description ?? "",
    location: activityEvent?.location ?? "",
    start_at: moment(activityEvent?.start_at) ?? moment().add(1, "days"),
    finish_at:
      moment(activityEvent?.finish_at) ??
      moment().add(1, "days").add(30, "minutes"),
    jap_customer_id: customer ?? null,
    contact_id: activityEvent?.contact ?? null,
    task_ids: activityEvent?.tasks ?? []
  }
}

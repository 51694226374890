import { useTodoTasks } from "entities/activities-task"
import { useActivityNotes } from "entities/activity-note"
import { useActivityEvents } from "entities/activity-event"

const getTodoTasksFilterParams = (filters, user) => {
  return {
    q: {
      title_cont: filters.searchTodoTask,
      done_eq: filters.showDoneTodos ? null : false,
      jap_customer_id_eq: filters.japCustomer?.id,
      creator_id_eq: user ? user.id : undefined,
      sorts: "due_date asc",
      ...(filters.contact?.id === "0"
        ? { contact_id_null: true }
        : { contact_id_eq: filters.contact?.id })
    }
  }
}

export const useFilteredTodoTasks = (useFilters, user) => {
  const filters = useFilters()

  return useTodoTasks(getTodoTasksFilterParams(filters, user))
}

const getActivityNotesFilterParams = (filters, user) => {
  return {
    q: {
      text_cont: filters.searchActivityNote,
      creator_id_eq: user ? user.id : undefined,
      jap_customer_id_eq: filters.japCustomer?.id
    }
  }
}

export const useFilteredActivityNotes = (useFilters, user) => {
  const filters = useFilters()

  return useActivityNotes(getActivityNotesFilterParams(filters, user))
}

const getActivityEventsFilterParams = (filters, user) => {
  return {
    q: {
      title_or_description_cont: filters.searchActivityEvent,
      creator_id_eq: user ? user.id : undefined,
      jap_customer_id_eq: filters.japCustomer?.id,
      ...(filters.eventContact?.id === "0"
        ? { contact_id_null: true }
        : { contact_id_eq: filters.eventContact?.id })
    }
  }
}

export const useFilteredActivityEvents = (useFilters, user) => {
  const filters = useFilters()

  return useActivityEvents(getActivityEventsFilterParams(filters, user))
}

import React from "react"
import { Translate } from "react-redux-i18n"

import { EmptyState } from "shared/ui/berry-jass-extended"

export const TableEmptyState = ({ sx = {} }) => {
  return (
    <EmptyState
      text={<Translate value="contacts.table.no-data-text" />}
      subText={<Translate value="contacts.table.no-data-sub-text" />}
      sx={{ border: "none", ...sx }}
    />
  )
}

import { proxy, useSnapshot } from "valtio"

const store = proxy({
  japCustomer: null,
  tasks: [],
  open: false
})

export const openDialog = ({ customer, showCustomer = false }) => {
  store.open = true
  store.japCustomer = customer
  store.showCustomer = showCustomer
}

export const closeDialog = () => {
  store.open = false
  store.tasks = []
  store.japCustomer = null
}

export const setTasks = (tasks) => {
  store.tasks = tasks
}

export const useStore = () => {
  return useSnapshot(store)
}

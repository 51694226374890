import React, { useCallback } from "react"

import { getTableProps } from "shared/lib/ransack"
import {
  completedColumn,
  getTitleColumn,
  expandColumn
} from "entities/activities-task"
import { TaskRemoveAction } from "../ui/TaskRemoveAction"

const getTodoColumns = (onRemoveTask) =>
  [
    completedColumn,
    getTitleColumn(),
    expandColumn,
    onRemoveTask && {
      id: "remove",
      size: 50,
      height: 65,
      cell: (info) => (
        <TaskRemoveAction
          task={info.row.original}
          onRemoveTask={onRemoveTask}
        />
      )
    }
  ].filter(Boolean)

export const useTodosTableProps = (onRemoveTask) => {
  return useCallback(
    (query) => {
      const props = getTableProps(query)
      return { ...props, columns: getTodoColumns(onRemoveTask) }
    },
    [onRemoveTask]
  )
}

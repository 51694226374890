import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import client from "utils/client"

export const useUpdateReminder = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (id) => {
      const response = await client.put(`/v2/activities/reminders/${id}.json`, {
        reminder: {
          seen_at: new Date().toISOString()
        }
      })

      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["v2", "activities", "tasks"])
    }
  })
}

export const useReminderNotifications = (options = {}) => {
  return useQuery({
    queryKey: ["v2", "activities", "reminders", "notifications", "index"],
    queryFn: async () => {
      const res = await client.get(
        "/v2/activities/notifications/reminders.json"
      )

      return res.data
    },
    enabled: false,
    ...options
  })
}

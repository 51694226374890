import React, { useRef } from "react"
import { I18n } from "react-redux-i18n"
import { getErrorText } from "./helpers"
import { TextField } from "@mui/material"
import { createFilterOptions } from "@mui/material/Autocomplete"
import { getIn } from "formik"
import isString from "lodash/isString"

import { RemoteAutocomplete as BerryRemoteAutocomplete } from "shared/ui"

const filter = createFilterOptions()

/**
 * Taken with adjustments from https://github.com/stackworx/formik-mui/blob/main/packages/formik-mui/src/Autocomplete.tsx
 */
const fieldToAutocomplete = ({
  disabled,
  field,
  form: { errors, touched, isSubmitting, setFieldValue, setFieldTouched },
  creatableOptionLabel,
  onChange,
  onInputChange = null,
  onBlur,
  freeSolo,
  renderInput,
  name,
  label,
  variant,
  textFieldVariant = "outlined",
  required,
  inputValue,
  inputValueMapper = (x) => x,
  ...props
}) => {
  if (process.env.NODE_ENV !== "production") {
    if (props.multiple) {
      // eslint-disable-next-line
      console.assert(
        Array.isArray(field.value),
        `value for ${field.name} is not an array, this can caused unexpected behaviour`
      )
    }
    // eslint-disable-next-line
    console.assert(
      field.value !== undefined,
      `Can't be undefined, possibly you forgot to pass initial value to <RemoteAutocomplete name="${field.name}" />`
    )
  }

  const fieldError = getIn(errors, field.name)
  const showError = getIn(touched, field.name) && !!fieldError

  const defaultRenderInput = (props) => {
    return (
      <TextField
        required={required}
        name={name}
        label={label}
        variant={textFieldVariant}
        error={showError}
        helperText={showError ? getErrorText(fieldError) : props.helperText}
        {...props}
      />
    )
  }

  const {
    onChange: _onChange,
    onBlur: _onBlur,
    multiple: _multiple,
    ...fieldSubselection
  } = field

  return {
    freeSolo,
    onBlur: function handleBlur(event) {
      if (onBlur) {
        onBlur(event)
      }

      if (freeSolo && inputValue.current) {
        setFieldValue(field.name, inputValueMapper(inputValue.current))
      }

      field.onBlur(event ?? field.name)
      setFieldTouched(field.name)
    },
    filterOptions: function handleFilterOptions(options, params) {
      const filtered = filter(options, params)

      if (!creatableOptionLabel) {
        return filtered
      }

      const { inputValue } = params
      const trimmedValue = inputValue.trim()

      if (!trimmedValue) {
        return filtered
      }

      // Suggest the creation of a new value
      const isExisting = options.some(
        (option) =>
          trimmedValue.toLowerCase() ===
          option[creatableOptionLabel].toLowerCase()
      )
      if (!isExisting) {
        filtered.push({
          inputValue: trimmedValue,
          [creatableOptionLabel]: I18n.t("add-value", {
            value: trimmedValue
          })
        })
      }

      return filtered
    },
    onChange: function handleChange(event, value, reason) {
      if (onChange) {
        onChange(event, value, reason)
      }

      let newValue

      if (Array.isArray(value) && creatableOptionLabel) {
        newValue = value.map((val) => {
          if (isString(val) && reason === "createOption") {
            return { [creatableOptionLabel]: val }
          }

          return val.inputValue
            ? { [creatableOptionLabel]: val.inputValue }
            : val
        })
      } else if (creatableOptionLabel && value?.inputValue) {
        newValue = { [creatableOptionLabel]: value.inputValue }
      } else {
        newValue = value
      }

      setFieldValue(field.name, newValue)
      setFieldTouched(field.name)
    },
    onInputChange: function handleInputValueChange(e, value, reason) {
      onInputChange && onInputChange(e, value, reason)

      if (freeSolo) {
        inputValue.current = value
      }
    },
    disabled: disabled ?? isSubmitting,
    loading: isSubmitting,
    variant,
    renderInput: renderInput ?? defaultRenderInput,
    ...fieldSubselection,
    ...props
  }
}

export const RemoteAutocomplete = (props) => {
  const inputValue = useRef(null)

  return (
    <BerryRemoteAutocomplete
      {...fieldToAutocomplete({ ...props, inputValue })}
    />
  )
}

RemoteAutocomplete.displayName = "FormikMaterialUIAutocomplete"

import { useQuery } from "@tanstack/react-query"
import client from "utils/client"

export const useCountries = () => {
  return useQuery(["autocomplete_countries"], async () => {
    const res = await client.get("/autocomplete/countries.json")

    return res.data.data
  })
}

import React, { memo } from "react"
import { I18n, Translate } from "react-redux-i18n"
import { Divider, Stack } from "@mui/material"

import { HorizontalStack } from "shared/ui/berry-jass"
import { RemoteAutocomplete } from "shared/ui"
import { SearchInput } from "shared/ui/berry-jass-extended"
import { CreateActivityEventFab } from "features/activity-event-create"
import { getAlternativeContactsOptionLabel } from "entities/contact"
import { contactAdditionalOptions } from "../lib"

export const ActivityEventsFilters = memo(
  ({ showCustomer, useFilters, setEventContact, setSearchActivityEvent }) => {
    const { eventContact, searchActivityEvent, japCustomer } = useFilters()

    return (
      <Stack gap={1}>
        <HorizontalStack gap={1}>
          <SearchInput
            fullWidth
            id="search"
            initialValue={searchActivityEvent}
            onChange={setSearchActivityEvent}
            size="small"
            placeholder={I18n.t("search.title")}
          />
          <CreateActivityEventFab
            showCustomer={showCustomer}
            customer={japCustomer}
            sx={{ p: 2 }}
          />
        </HorizontalStack>

        <RemoteAutocomplete
          paginated
          id="contact_filter"
          size="small"
          disabled={!japCustomer?.id}
          data-testid="contact-select"
          additionalOptions={contactAdditionalOptions}
          label={<Translate value="activities-center.filters.contact" />}
          mapResponseToDataSource={(response) => response?.data ?? []}
          onChange={(_e, value) => setEventContact(value)}
          url="/autocomplete/jap_customers/contacts"
          value={eventContact?.id ? eventContact : null}
          params={{ q: { by_customer_id: japCustomer?.id } }}
          variant="ransack"
          searchProp="q.first_name_or_last_name_or_phone_or_email_or_company_cont"
          getOptionLabel={getAlternativeContactsOptionLabel}
        />

        <Divider variant="fullWidth" sx={{ pb: 2 }} />
      </Stack>
    )
  }
)

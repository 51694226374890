import { useMutation, useQueryClient } from "@tanstack/react-query"
import client from "utils/client"

export const useConfirmUser = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (id) => {
      const response = await client.post(`/v2/users/${id}/confirmations.json`)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["internal_users"]
      })
      queryClient.invalidateQueries({
        queryKey: ["v2_members", "index"]
      })
    }
  })
}

export const useChangePassword = () => {
  return useMutation({
    mutationFn: async (values) => {
      let url = "password/edit.json"

      if (values.reset_password_token) {
        url = "/users/password.json"
      } else if (values.confirmation_token) {
        url = "/users/confirmations.json"
      }

      const res = await client.put(url, {
        user: values
      })

      return res.data
    }
  })
}

export const useRequestPasswordChange = () => {
  return useMutation({
    mutationFn: async (values) => {
      const res = await client.post("/users/password.json", {
        user: values
      })
      return res.data
    }
  })
}

export const useLogin = () => {
  return useMutation({
    mutationFn: async ({ email, password }) => {
      const response = await client.post(
        "/session_tokens.json",
        {
          email,
          password
        },
        { withCredentials: true }
      )

      return response
    }
  })
}

import {
  useInfiniteQuery,
  useQuery,
  useMutation,
  useQueryClient
} from "@tanstack/react-query"

import client from "utils/client"
import { getNextPageParam } from "shared/lib/ransack"

export const useActivityNotes = (params) => {
  return useInfiniteQuery({
    queryKey: ["v2", "activities", "notes", "index", params],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await client.get("/v2/activities/notes.json", {
        params: { ...params, page: pageParam }
      })

      return res.data
    },
    getNextPageParam
  })
}

export const useActivityNote = (id, options = {}) => {
  return useQuery({
    queryKey: ["v2", "activities", "notes", "show", id],
    queryFn: async () => {
      const response = await client.get(`/v2/activities/notes/${id}.json`)

      return response.data
    },
    enabled: !!id,
    ...options
  })
}

export const useUpdateActivityNote = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ id, ...values }) => {
      const response = await client.put(`/v2/activities/notes/${id}.json`, {
        activity_note: values
      })

      return response.data
    },

    onSuccess: () => {
      queryClient.invalidateQueries(["v2", "activities", "notes", "index"])
      queryClient.invalidateQueries(["v2", "activities", "notes", "show"])
      queryClient.invalidateQueries(["v2", "activities", "tasks", "index"])
    }
  })
}

export const useCreateActivityNote = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (values) => {
      const response = await client.post("/v2/activities/notes.json", {
        activity_note: values
      })

      return response.data
    },

    onSuccess: () => {
      queryClient.invalidateQueries(["v2", "activities", "notes", "index"])
    }
  })
}

export const useDeleteActivityNote = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (id) => {
      const response = await client.delete(`/v2/activities/notes/${id}.json`)

      return response.data
    },

    onSuccess: () => {
      queryClient.invalidateQueries(["v2", "activities", "notes", "index"])
    }
  })
}

import React from "react"
import { useHistory } from "react-router"
import { Box, Link } from "@mui/material"

import useUser from "hooks/useUser"
import { getPerformanceUrl } from "../lib"

export const CustomerPerformanceLink = ({ activity }) => {
  const user = useUser()
  const history = useHistory()

  const { customer_name, jap_customer_id } = activity
  const performanceUrl = getPerformanceUrl({ ...activity, user })

  return (
    <Box sx={{ fontSize: 12, fontWeight: 400 }}>
      {performanceUrl ? (
        <Link
          data-testid={`performance-link-${jap_customer_id}`}
          underline="none"
          sx={{ cursor: "pointer" }}
          onClick={() => history.push(performanceUrl)}
        >
          {customer_name}
        </Link>
      ) : (
        customer_name
      )}
    </Box>
  )
}

import React from "react"
import { Box, Typography } from "@mui/material"
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined"
import objectHash from "object-hash"

import { Wysiwyg } from "shared/ui/berry-jass"
import { TasksTable } from "entities/activity-event"
import { getAlternativeContactsOptionLabel } from "entities/contact"

export const ActivityEventsTableSubComponent = ({ row, customerId }) => {
  const { id, description, contact } = row.original
  const key = objectHash({ id, description }) // to rerender Wysiwyg on update

  return (
    <Box sx={{ py: 0, pl: 2 }}>
      {contact && (
        <Box display="flex" alignItems="center">
          <Person2OutlinedIcon
            color="primary"
            sx={{ fontSize: "16px", mr: "8px" }}
          />
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: 400
            }}
          >
            {getAlternativeContactsOptionLabel(contact)}
          </Typography>
        </Box>
      )}
      {!!description && (
        <Typography
          sx={{
            fontSize: 12,
            fontWeight: 400
          }}
        >
          <Wysiwyg // workaround to display text with formatting
            disabled
            toolbarHidden
            key={key}
            value={description}
            sx={{
              border: "unset",
              "& .rdw-editor-main": { minHeight: "unset", p: 0 }
            }}
          />
        </Typography>
      )}

      <TasksTable customerId={customerId} eventId={id} emptyState={<></>} />
    </Box>
  )
}

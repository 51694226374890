import React from "react"
import { Field, FieldArray } from "formik"
import { I18n, Translate } from "react-redux-i18n"
import {
  FormControlLabel,
  Grid,
  TableRow,
  Table,
  TableHead,
  TableCell,
  TableBody,
  IconButton,
  Fab
} from "@mui/material"
import { Checkbox, TextField } from "shared/ui/berry-jass-formik"
import CloseIcon from "@mui/icons-material/Close"
import AddIcon from "@mui/icons-material/Add"
import { v4 as uuidv4 } from "uuid"
import { HorizontalStack } from "shared/ui/berry-jass"

const cell = {
  verticalAlign: "middle",
  textAlign: "center",
  padding: 1
}

const head = {
  borderRadius: 0
}

export const ProductFieldsForm = ({ formik: { isSubmitting, values } }) => {
  return (
    <Grid container rowSpacing={3} columnSpacing={2}>
      <Grid item md={12}>
        <FieldArray
          name="dynamic_fields"
          render={(formHelpers) => (
            <>
              <HorizontalStack justifyContent="flex-end" sx={{ m: 0, p: 0 }}>
                <Fab
                  disabled={isSubmitting}
                  color="primary"
                  title={I18n.t("add")}
                  size="small"
                  onClick={() =>
                    formHelpers.unshift({
                      virtualId: uuidv4(),
                      name: "",
                      technical_name: "",
                      count: "",
                      length: "",
                      specific: false,
                      is_mandatory: false,
                      not_removable: false,
                      selected: false
                    })
                  }
                >
                  <AddIcon sx={{ fontSize: 16 }} />
                </Fab>
              </HorizontalStack>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={head} width="10px" />
                    <TableCell sx={head} width="150px">
                      <Translate value="name" />
                    </TableCell>
                    <TableCell sx={head} width="150px">
                      <Translate value="technical-name" />
                    </TableCell>
                    <TableCell sx={head} width="150px">
                      <Translate value="schalttool-field" />
                    </TableCell>
                    <TableCell sx={head} width="20px">
                      <Translate value="length" />
                    </TableCell>
                    <TableCell sx={head} width="20px">
                      <Translate value="count" />
                    </TableCell>
                    <TableCell sx={head} width="20px">
                      <Translate value="specific" />
                    </TableCell>
                    <TableCell sx={head} width="20px">
                      <Translate value="mandatory" />
                    </TableCell>
                    <TableCell sx={head} width="20px" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {values.dynamic_fields?.map((dynamicField, index) => {
                    return (
                      <TableRow key={dynamicField.id ?? dynamicField.virtualId}>
                        <TableCell sx={cell}>
                          <Field
                            name={`dynamic_fields.${index}.selected`}
                            component={Checkbox}
                          />
                        </TableCell>
                        <TableCell sx={cell}>
                          <Field
                            fullWidth
                            name={`dynamic_fields.${index}.name`}
                            component={TextField}
                          />
                        </TableCell>
                        <TableCell sx={cell}>
                          <Field
                            fullWidth
                            name={`dynamic_fields.${index}.technical_name`}
                            disabled={!!dynamicField.id}
                            component={TextField}
                          />
                        </TableCell>
                        <TableCell sx={cell}>
                          <Field
                            fullWidth
                            name={`dynamic_fields.${index}.schalttool_field`}
                            component={TextField}
                          />
                        </TableCell>
                        <TableCell sx={cell}>
                          <Field
                            fullWidth
                            disabled={!dynamicField.selected}
                            name={`dynamic_fields.${index}.length`}
                            component={TextField}
                          />
                        </TableCell>
                        <TableCell sx={cell}>
                          <Field
                            fullWidth
                            disabled={!dynamicField.selected}
                            name={`dynamic_fields.${index}.count`}
                            component={TextField}
                          />
                        </TableCell>
                        <TableCell sx={cell}>
                          <FormControlLabel
                            control={
                              <Field
                                disabled={!dynamicField.specific_is_changeable}
                                name={`dynamic_fields.${index}.specific`}
                                component={Checkbox}
                              />
                            }
                            label=""
                          />
                        </TableCell>
                        <TableCell sx={cell}>
                          <FormControlLabel
                            control={
                              <Field
                                disabled={!dynamicField.selected}
                                name={`dynamic_fields.${index}.is_mandatory`}
                                component={Checkbox}
                              />
                            }
                            label=""
                          />
                        </TableCell>
                        <TableCell sx={cell}>
                          <IconButton
                            disabled={dynamicField.not_removable}
                            title={I18n.t("actions.delete")}
                            onClick={() => {
                              formHelpers.remove(index)
                            }}
                            size="large"
                          >
                            <CloseIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </>
          )}
        />
      </Grid>
    </Grid>
  )
}

import React from "react"
import { Stack } from "@mui/material"

import {
  DateTimePicker,
  TextField,
  RemoteAutocomplete
} from "shared/ui/berry-jass-formik"
import { Translate } from "react-redux-i18n"
import { connect, Field } from "formik"
import { getAlternativeContactsOptionLabel } from "entities/contact"

export const ActivityEventForm = connect(({ formik: { values } }) => {
  return (
    <Stack spacing={2}>
      <Field
        fullWidth
        name="contact_id"
        disabled={!values.jap_customer_id}
        component={RemoteAutocomplete}
        paginated
        id="contact_id"
        size="small"
        data-testid="contact-select"
        label={<Translate value="activities-center.filters.contact" />}
        mapResponseToDataSource={(response) => response?.data ?? []}
        url="/autocomplete/jap_customers/contacts"
        params={{ q: { by_customer_id: values.jap_customer_id?.id } }}
        variant="ransack"
        searchProp="q.first_name_or_last_name_or_phone_or_email_or_company_cont"
        getOptionLabel={getAlternativeContactsOptionLabel}
      />
      <Field
        fullWidth
        required
        name="title"
        label={<Translate value="event-dialog.fields.subject" />}
        component={TextField}
      />
      <Field
        id="start-at-datepicker"
        name="start_at"
        required
        clearable
        disablePast
        ampm={false}
        component={DateTimePicker}
        label={<Translate value="event-dialog.fields.start-date" />}
      />
      <Field
        id="finish-at-datepicker"
        name="finish_at"
        required
        clearable
        disablePast
        ampm={false}
        component={DateTimePicker}
        label={<Translate value="event-dialog.fields.end-date" />}
      />
      <Field
        fullWidth
        name="location"
        label={<Translate value="event-dialog.fields.location" />}
        component={TextField}
      />
      <Field
        fullWidth
        name="description"
        label={<Translate value="event-dialog.fields.description" />}
        component={TextField}
        multiline
        rows={5}
      />
    </Stack>
  )
})

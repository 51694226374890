import { Formik } from "formik"
import React from "react"
import { Translate } from "react-redux-i18n"
import { LoadingButton } from "@mui/lab"
import { Button, DialogActions, DialogContent, useTheme } from "@mui/material"
import { Dialog } from "shared/ui/berry-jass"
import { TaskForm, taskSchema } from "entities/activities-task"
import { getInitialValues } from "../lib"
import { closeDialog, useHandleCreateTask, useStore } from "../model"

const FORM_ID = "todo-task-create-form"

export const CreateTodoTaskDialog = ({ setCustomer, showCustomer }) => {
  const { open, japCustomer, contact } = useStore()
  const handleCreateTask = useHandleCreateTask(japCustomer?.id)
  const initialValues = getInitialValues(japCustomer, contact)
  const theme = useTheme()

  if (!open) {
    return null
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={taskSchema}
      onSubmit={handleCreateTask}
      render={({ isSubmitting, handleSubmit, setFieldValue, values }) => {
        const handleDeleteAttachment = (_file, index) => {
          const updatedFiles = [...values.attachments]
          updatedFiles.splice(index, 1)
          setFieldValue("attachments", updatedFiles)
        }

        return (
          <Dialog
            open={open}
            onClose={closeDialog}
            data-testid="create-task-dialog"
            maxWidth="lg"
            titleSx={{ pb: 2 }}
            title={<Translate value="todo-dialog.create-todo" />}
          >
            <DialogContent
              dividers
              sx={{
                pt: 3,
                [theme.breakpoints.up("md")]: {
                  minWidth: 625
                }
              }}
            >
              <form
                id={FORM_ID}
                onSubmit={async (e) => {
                  await setFieldValue(
                    "submitter",
                    e.nativeEvent.submitter?.name
                  )
                  handleSubmit(e)
                }}
              >
                <TaskForm
                  showCustomer={showCustomer}
                  setCustomer={setCustomer}
                  handleDeleteAttachment={handleDeleteAttachment}
                />
              </form>
            </DialogContent>
            <DialogActions sx={{ pt: 2 }}>
              <Button
                onClick={closeDialog}
                variant="outlined"
                sx={{
                  display: { xs: "none", md: "block" }
                }}
              >
                <Translate value="actions.cancel" />
              </Button>
              <LoadingButton
                form={FORM_ID}
                loading={isSubmitting}
                type="submit"
                variant="contained"
              >
                <Translate value="create" />
              </LoadingButton>
              <LoadingButton
                loading={isSubmitting}
                form={FORM_ID}
                type="submit"
                name="create_and_new"
                variant="contained"
              >
                <Translate value="todo-dialog.create-and-new" />
              </LoadingButton>
            </DialogActions>
          </Dialog>
        )
      }}
    />
  )
}

import React, { memo } from "react"
import { I18n, Translate } from "react-redux-i18n"
import { Box, Stack } from "@mui/material"
import AttachmentIcon from "@mui/icons-material/Attachment"
import NotificationsIcon from "@mui/icons-material/Notifications"
import moment from "moment"

import { CustomerPerformanceLink } from "entities/activity"

export const ActivityTaskCell = memo(({ activity, showCustomer }) => {
  const { title, due_date, contact_name, reminder, done, attachments } =
    activity
  const iconSx = {
    fontSize: "14px !important",
    color: "primary.main",
    alignItems: "center"
  }
  const dueDate = moment(due_date)

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
      }}
    >
      <Box sx={{ fontSize: 14, fontWeight: 500 }}>{title}</Box>
      {contact_name && (
        <Box sx={{ fontSize: 12, fontWeight: 400 }}>{contact_name}</Box>
      )}

      {showCustomer && <CustomerPerformanceLink activity={activity} />}

      <Box sx={{ fontSize: 12, fontWeight: 400 }}>
        <Stack spacing={1} direction="row">
          <Box
            sx={{
              color:
                !done && dueDate.isBefore(moment(), "day") ? "error.main" : null
            }}
          >
            <Translate
              value="activities.task-due-date"
              date={dueDate.format(I18n.t("time.formats.weekday-month"))}
            />
          </Box>
          {!!reminder && <NotificationsIcon sx={iconSx} />}
          {!!attachments?.length && <AttachmentIcon sx={iconSx} />}
        </Stack>
      </Box>
    </Box>
  )
})

import useHandleServerErrors from "hooks/useHandleServerErrors"
import {
  activityNoteSchema,
  useCreateActivityNote
} from "entities/activity-note"
import { closeDialog } from "./store"

export const useHandleCreateActivityNote = (jap_customer_id) => {
  const createActivityNote = useCreateActivityNote()
  const handleServerErrors = useHandleServerErrors()

  return (values, formik) => {
    createActivityNote.mutate(
      activityNoteSchema.cast({ jap_customer_id, ...values }),
      {
        onSuccess() {
          formik.resetForm()
          closeDialog()
        },
        onError: (error) => handleServerErrors(error),
        onSettled() {
          formik.setSubmitting(false)
        }
      }
    )
  }
}

import { object, string, array, mixed } from "yup"

export const activityNoteSchema = object({
  jap_customer_id: mixed()
    .required("required")
    .transform((japCustomer) => japCustomer?.id),
  text: string().required("required"),
  task_ids: array().of(mixed().transform((value) => value?.id))
})

export const editActivityNoteSchema = object({
  text: string().required("required"),
  task_ids: array().of(mixed().transform((value) => value?.id))
})

import { proxy, useSnapshot } from "valtio"

const store = proxy({
  activityNote: null,
  japCustomerId: null,
  tasks: [],
  open: false
})

export const openDialog = ({ japCustomerId, activityNote }) => {
  store.open = true
  store.activityNote = activityNote
  store.japCustomerId = japCustomerId
}

export const closeDialog = () => {
  store.open = false
  store.activityNote = null
  store.japCustomerId = null
  store.tasks = []
}

export const setTasks = (tasks) => {
  store.tasks = tasks
}

export const useStore = () => {
  return useSnapshot(store)
}

import { Form, Formik } from "formik"
import React from "react"
import { Translate } from "react-redux-i18n"
import { LoadingButton } from "@mui/lab"
import { Button, DialogActions, DialogContent, useTheme } from "@mui/material"
import { Dialog } from "shared/ui/berry-jass"
import { TaskForm, taskSchema, useTask } from "entities/activities-task"
import { getInitialValues } from "../lib"
import {
  closeDialog,
  useHandleUpdateTask,
  useHandleDeleteAttachment,
  useStore
} from "../model"

export const EditTodoTaskDialog = () => {
  const { open, task } = useStore()
  const handleUpdateTask = useHandleUpdateTask()
  const handleDeleteAttachment = useHandleDeleteAttachment()
  const { data: taskInfo, isFetched } = useTask(task?.id)
  const theme = useTheme()

  const initialValues = getInitialValues(taskInfo)

  if (!open || !isFetched) {
    return null
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={taskSchema}
      onSubmit={handleUpdateTask}
      render={({ isSubmitting }) => {
        return (
          <Dialog
            open={open}
            onClose={closeDialog}
            maxWidth="lg"
            titleSx={{ pb: 2 }}
            data-testid="edit-task-dialog"
            title={<Translate value="todo-dialog.edit-todo" />}
          >
            <DialogContent
              dividers
              sx={{
                pt: 3,
                [theme.breakpoints.up("md")]: {
                  minWidth: 625
                }
              }}
            >
              <Form id="form">
                <TaskForm handleDeleteAttachment={handleDeleteAttachment} />
              </Form>
            </DialogContent>
            <DialogActions sx={{ pt: 2 }}>
              <Button
                onClick={closeDialog}
                variant="outlined"
                sx={{
                  display: { xs: "none", md: "block" }
                }}
              >
                <Translate value="actions.cancel" />
              </Button>
              <LoadingButton
                form="form"
                loading={isSubmitting}
                type="submit"
                variant="contained"
              >
                <Translate value="save" />
              </LoadingButton>
            </DialogActions>
          </Dialog>
        )
      }}
    />
  )
}
